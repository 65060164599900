import { merge } from 'lodash-es';
import axios from '@/axios';
import constants from '@/constants/constants';
import UnknownPrototypeError from '@/errors/UnknownPrototypeError';
import FormPrototype from './FormPrototype';
import Parameter from './Parameter';
import { store } from '@/main';

const PRICE_RELATED_PARAMETER_CODES = [
    'LENS_PRICE',
    'RETURN_CREDIT_AMOUNT',
];

/**
 * @class
 */
export default class ReturnQuestionnaire
{
    eid = null;

    surveyCode = null;

    parameters = null;

    templating = false;

    validating = false;

    /**
     * AbortController object.
     */
    abortController = null;

    // ------------------------------------------------------------------------- CONSTRUCTOR

    constructor(eid, surveyCode)
    {
        this.eid = eid;
        this.surveyCode = surveyCode;
        this.parameters = new Map();
    }

    // ------------------------------------------------------------------------- TEMPLATE

    template(initValues = null, additionalValues = {})
    {
        // Set "templating" status
        this.templating = true;

        // If a request was already ongoing, abort it
        if(this.abortController)
        {
            this.abortController.abort();
        }

        // Build URL
        const url = '/api/prototype/template/:eid/:surveyCode'
            .replace(':eid', this.eid)
            .replace(':surveyCode', this.surveyCode);

        // Get parameter values (and merge additional values (if any)).
        const data = {
            values: Object.assign({}, this.getValues(), additionalValues),
        };

        // Create AbortController
        this.abortController = new AbortController();

        // Run query
        return axios.post(url, data, { signal: this.abortController.signal })
            .then(response =>
            {
                // Reset AbortController
                this.abortController = null;

                // Set parameters (template).
                this.setParameters(response.data);

                // If initial values have been given => set parameter values with them.
                if(initValues)
                {
                    this.setValues(initValues);
                }

                return Promise.resolve(response);
            })
            .catch(error =>
            {
                if(!axios.isCancel(error))
                {
                    console.log(error);

                    throw error;
                }
            })
            .finally(() =>
            {
                this.templating = false;
            });
    }

    // ------------------------------------------------------------------------- VALIDATION

    validate(options = {})
    {
        // Set "validating" status
        this.validating = true;

        // Build URL
        const url = ('/api/prototype/validate/:eid/:surveyCode')
            .replace(':eid', this.eid)
            .replace(':surveyCode', this.surveyCode);

        // Get parameter values.
        const data = {
            values:  this.getValues(),
            options: options,
        };

        // Run query
        return axios.post(url, data)
            .then(response =>
            {
                this.clearErrors();

                return Promise.resolve(response);
            })
            .catch(error =>
            {
                this.clearErrors();
                this.setErrors(error.response.errors);

                console.log('ERRORS:', error.response.errors);

                throw error;
            })
            .finally(() =>
            {
                this.validating = false;
            });
    }

    setErrors(errors)
    {
        for(const parameterCode in errors)
        {
            if(this.parameters.has(parameterCode))
            {
                this.parameters.get(parameterCode).setError(errors[parameterCode][0]);
            }
        }
    }

    getErrorByParameterCode(parameterCode)
    {
        if(this.parameters.has(parameterCode))
        {
            return this.parameters.get(parameterCode).getError();
        }

        return null;
    }

    clearErrors()
    {
        this.parameters.forEach(parameter =>
        {
            parameter.clearError();
        });
    }

    // ------------------------------------------------------------------------- PARAMETERS

    getParameters()
    {
        return this.parameters;
    }

    getParameterByCode(parameterCode)
    {
        return (this.parameters.has(parameterCode)) ? this.parameters.get(parameterCode) : null;
    }

    setParameters(parameters)
    {
        const codes = [];

        // Parameters that can be hidden because of user access.
        let hiddenParameters = (store.getters['account/can']('price:view')) ? [] : PRICE_RELATED_PARAMETER_CODES;

        // Create parameter instance and add it to parameters map (if not already exists) or update existing parameter instance.
        parameters.forEach(parameter =>
        {
            // If current parameter can be seen by current user (regarding of its permissions).
            if(!hiddenParameters.includes(parameter.code))
            {
                codes.push(parameter.code);

                if(this.parameters.has(parameter.code))
                {
                    this.parameters.get(parameter.code).setProperties(parameter);
                }
                else
                {
                    this.parameters.set(parameter.code, new Parameter(parameter));
                }
            }
        });

        // Delete parameter instance that does not exist anymore (regarding given "parameters" arguments).
        this.parameters.forEach((parameter, key) =>
        {
            if(!codes.includes(key))
            {
                this.parameters.delete(key);
            }
        });
    }

    // ------------------------------------------------------------------------- VALUES

    setValue(parameterCode, parameterValue)
    {
        if(this.parameters.has(parameterCode))
        {
            this.parameters.get(parameterCode).setValue(parameterValue);
        }
    }

    getValueByCode(parameterCode)
    {
        return (this.parameters.has(parameterCode)) ? this.parameters.get(parameterCode).getValue() : null;
    }

    getValues(nonNullValueOnly = false)
    {
        const values = {};

        this.parameters.forEach((parameter, key) =>
        {
            const value = parameter.getValue();

            if(!nonNullValueOnly || value !== null)
            {
                values[key] = value;
            }
        });

        return values;
    }
}
