<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <lsn-table
        ref="orderTable"
        :data="orders"
        class="sl-orders"
        openable
    >

        <!-- Table Header -->
        <template #header>
            <lsn-table-cell class="sl-order-cell__date">
                {{ $t('order_table.headers.date') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-order-cell__reference">
                {{ $t('order_table.headers.reference') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-order-cell__article">
                {{ $t('order_table.headers.article') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-order-cell__status">
                {{ $t('order_table.headers.status') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-order-cell__quantity">
                {{ $t('order_table.headers.quantity_abbreviated') }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-order-cell__price">
                {{ $t('order_table.headers.price') }}
            </lsn-table-cell>

            <lsn-table-cell v-if="context !== 'order-confirmation'" class="sl-order-cell__actions" />
        </template>

        <!-- Table Body -->
        <template #summary="{ item }">
            <component
                :is="getLineSummaryComponent(item)"
                :order="item"
                :is-re-ordered="isReOrdered(item)"
                :context="context"
                @reordered="reordered"
                @unreordered="unreordered"
            />
        </template>

        <template #details="{ item }">
            <component
                :is="getLineDetailsComponent(item)"
                :order="item"
                :context="context"
            />
        </template>

    </lsn-table>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import LsnTable                from '@/components/LsnTable/LsnTable.vue';
import LsnTableCell            from '@/components/LsnTable/LsnTableCell.vue';
import LensOrderLineSummary    from '@/components/order/LensOrderLineSummary.vue';
import LensOrderLineDetails    from '@/components/order/LensOrderLineDetails.vue';
import DefaultOrderLineSummary from '@/components/order/DefaultOrderLineSummary.vue';

export default
{
    name: 'OrderTable',

    components:
    {
        LsnTable,
        LsnTableCell,
    },

    props:
    {
        orders:
        {
            type:     Array,
            required: true,
        },

        context:
        {
            type:     String,
            required: true,
        },

        reOrders:
        {
            type:    Array,
            default: () => [],
        },
    },

    emits: [
        'reordered',
        'unreordered',
    ],

    methods:
    {
        getLineSummaryComponent(order)
        {
            if(order.article.tags.some(a => a.code === 'LENS'))
            {
                return LensOrderLineSummary;
            }
            else
            {
                return DefaultOrderLineSummary;
            }
        },

        getLineDetailsComponent(order)
        {
            if(order.article.tags.some(a => a.code === 'LENS'))
            {
                return LensOrderLineDetails;
            }
            else
            {
                //return DefaultOrderLineDetails;
            }
        },

        isReOrdered(order)
        {
            for(const i in this.reOrders)
            {
                if(order.id == this.reOrders[i].id)
                {
                    return true;
                }
            }

            return false;
        },

        reordered(order)
        {
            this.$emit('reordered', order);
        },

        unreordered(order)
        {
            this.$emit('unreordered', order);
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-orders
{
    // Column width definitions
    --column-width-date:      90px;
    --column-width-sn:        90px;
    --column-width-article:    1fr;
    --column-width-status:   110px;
    --column-width-quantity:  60px;
    --column-width-price:    110px;
    --column-width-actions:  104px;
}

.sl-orders.lsn-table:not(.lsn-table--selectable) :deep(> .lsn-table__header)
{
    grid-template-columns:
        var(--open-toggle-width)
        var(--column-width-date)
        var(--column-width-sn)
        var(--column-width-article)
        var(--column-width-status)
        var(--column-width-quantity)
        var(--column-width-price)
        var(--column-width-actions);
}

.sl-orders.lsn-table:not(.lsn-table--selectable) :deep(> .lsn-table__body > .lsn-table__line > .lsn-table__line-summary)
{
    grid-template-columns:
        var(--open-toggle-width)
        var(--column-width-date)
        var(--column-width-sn)
        var(--column-width-article)
        var(--column-width-status)
        var(--column-width-quantity)
        var(--column-width-price)
        var(--column-width-actions);

    @apply py-1;
}

.sl-orders :deep(.sl-order-cell__reference)
{
    @apply text-center;
}

.sl-orders :deep(.sl-order-cell__ext-ref)
{
    @apply text-center;
}

.sl-orders :deep(.sl-order-cell__status)
{
    @apply text-center;
}

.sl-orders :deep(.sl-order-cell__quantity)
{
    @apply text-center;
}

.sl-orders :deep(.sl-order-cell__discount)
{
    @apply text-center;
}

.sl-orders :deep(.sl-order-cell__price)
{
    @apply flex justify-between items-center text-center;
}

.sl-orders :deep(.sl-order-cell__actions)
{
    @apply text-right;
}
</style>
