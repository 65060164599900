<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line-column flex flex-col h-full" :class="columnClasses">
        <!-- Label -->
        <div class="sl-order-form__label-wrapper">
            <span class="sl-order-form__label">
                {{ parameter.form_label }}
            </span>
        </div>

        <!-- Field -->
        <div class="grow">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="px-2 text-left text-primary-500 text-sm leading-8" v-html="displayedValue"></div>
        </div>

        <!-- Error -->
        <div v-if="error" class="mb-1 px-2 text-red-600 text-xs text-center">
            {{ error }}
        </div>
    </div>
</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractInlineField from './AbstractInlineField.vue';

export default
{
    name: 'InlineInfoField',

    extends: AbstractInlineField,

    computed:
    {
        displayedValue()
        {
            if(this.parameter.defined_value_label !== null)
            {
                return this.parameter.defined_value_label;
            }

            if(this.parameter.defined_value !== null)
            {
                return this.parameter.defined_value;
            }

            const tkey = `parameters.${this.parameter.code}.values.${this.modelValue}.label`;
            const translation = this.$t(tkey);
            if(translation !== tkey)
            {
                return translation;
            }

            if(this.modelValue === null)
            {
                // return '<span class="text-gray-400">&empty;</span>';
                return '&nbsp;';
            }

            return this.modelValue;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>
