<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->
<!-- eslint-disable vue/no-v-html -->

<template>
    <div class="sl-order-form__line sl-order-form__line-grouped" :class="{ 'sl-order-form__line--error': error }">
        <!-- Label -->
        <slot name="label" />

        <!-- Field Wrapper -->
        <div class="flex justify-center w-full">
            <div
                class="sl-order-form__field-wrapper lsn-form__radio-item-wrapper w-auto"
                style="width: auto;"
                :tabindex="tabIndex"
                @keydown.up.prevent="selectPrevious()"
                @keydown.left.prevent="selectPrevious()"
                @keydown.right.prevent="selectNext()"
                @keydown.down.prevent="selectNext()"
                @keydown.space.prevent="toggle()"
            >
                <!-- Field -->
                <div
                    v-for="item in parameter.list_items"
                    :key="item.ovalue"
                    class="lsn-form__radio-item"
                    :class="{ 'lsn-form__radio-item--selected': item.ovalue == modelValue, 'lsn-form__radio-item--disabled': !item.is_enabled }"
                    @click="setValue(item)"
                >
                    <!-- Label -->
                    <div v-html="item.label"></div>

                    <!-- Help -->
                    <div
                        v-if="item.help"
                        v-tippy="{ animation : 'fade' }"
                        class="lsn-form__radio-item-help"
                        :content="item.help"
                    >
                        <mdi-icon icon="mdiInformationOutline" />
                    </div>
                </div>
            </div>
        </div>

        <!-- Supplement -->
        <div v-if="supplement" class="sl-order-form__supplement-wrapper">
            <div class="sl-order-form__supplement">
                {{ supplement }}
            </div>
        </div>

        <!-- Note -->
        <div v-if="parameter.note" class="sl-order-form__note-wrapper">
            <div class="sl-order-form__note">
                {{ parameter.note }}
            </div>
        </div>

        <!-- Error -->
        <div v-if="error" class="sl-order-form__error-wrapper">
            <div class="sl-order-form__error">
                {{ error }}
            </div>
        </div>

        <!-- Splitter -->
        <slot name="splitter" />
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractGroupedField from './AbstractGroupedField.vue';

export default
{
    name: 'GroupedRadioButtonField',

    extends: AbstractGroupedField,

    emits: ['update:modelValue'],

    data()
    {
        return {
            isTouched: false,
        };
    },

    computed:
    {
        cIndex()
        {
            return this.parameter.list_items.findIndex(i => i.ovalue == this.modelValue);
        },
    },

    methods:
    {
        setValue(item)
        {
            if(!item.is_enabled)
            {
                return;
            }

            this.isTouched = true;

            let val = item.ovalue;
            if(item.ovalue == this.modelValue && !this.parameter.is_required)
            {
                // Allow de-selecting current value if field is not required
                val = null;
            }

            this.$emit('update:modelValue', val);
        },

        selectPrevious()
        {
            let prevIndex = this.cIndex;
            for(let i = (this.cIndex === -1 ? this.parameter.list_items.length : this.cIndex) - 1; i >= 0; i--)
            {
                if(this.parameter.list_items[i].is_enabled)
                {
                    prevIndex = i;
                    break;
                }
            }

            if(prevIndex !== this.cIndex)
            {
                this.setValue(this.parameter.list_items[prevIndex]);
            }
        },

        selectNext()
        {
            let nextIndex = this.cIndex;
            for(let i = this.cIndex + 1; i < this.parameter.list_items.length; i++)
            {
                if(this.parameter.list_items[i].is_enabled)
                {
                    nextIndex = i;
                    break;
                }
            }

            if(nextIndex !== this.cIndex)
            {
                this.setValue(this.parameter.list_items[nextIndex]);
            }
        },

        toggle()
        {
            if(this.modelValue !== null)
            {
                if(!this.parameter.is_required)
                {
                    this.$emit('update:modelValue', null);
                }

                return;
            }

            this.selectNext();
        },
    },
};
</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__line
{
    @apply pb-1;
}

.sl-order-form__supplement-wrapper
{
    @apply mt-2;
}
</style>
