export default class CheckAlreadyShipped
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const returns = [
            this.v.leftReturn,
            this.v.rightReturn,
        ];

        for(let ret of returns)
        {
            if(ret)
            {
                const shippingStatus = ret.order.status.find(status => status.code == 'SHIPPED');

                if(!shippingStatus)
                {
                    console.log(`The order ${ret.order.reference} has not been shipped yet.`);

                    let tkey = (ret.isMain) ? 'return.form.error.not_shipped' : 'return.form.error.paired_not_shipped';
                    this.v.orderIdentifierFormError = this.v.$t(tkey);

                    return false;
                }
            }
        }

        return true;
    }
}
