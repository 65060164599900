import axios from '@/axios';
import Order from '@/models/Order';
import Return from '@/models/Return';
import LensReturnUrlManager from '../LensReturnUrlManager';

export default class FetchOrderByLensOemNumber
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        /** @type {LensReturnUrlManager} */
        const lensReturnUrlManager = this.v.lensReturnUrlManager;

        // Skip unless there's a lens OEM number in the URL
        if(!lensReturnUrlManager.hasOemNumber())
        {
            console.log(`No lens OEM number in URL for `);
            return true;
        }

        const urlOemNumber = lensReturnUrlManager.getOemNumber();
        console.log(`There is a lens OEM number in URL: ${urlOemNumber}`);

        return axios.get(`/api/order/lens-oem/${urlOemNumber}`)
            .then(response =>
            {
                // Set return
                const order = new Order(response.data);
                const ret = new Return(order);
                ret.isMain = true;
                this.v.setReturn(ret);

                console.log(`Main return has been set.`);

                return true;
            })
            .catch(err =>
            {
                this.v.orderIdentifierFormError = this.v.$t('return.form.error.not_found');

                return false;
            });
    }
}
