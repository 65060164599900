<!-- eslint-disable vue/no-v-html -->
<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="flex px-4">

        <!-- First Column: Lens Parameters -->

        <div class="sl-return-details-col">
            <div class="sl-return-details__title">
                {{ t('order_table.details.section.lens_parameters.title') }}
            </div>

            <!-- Parameters Table -->
            <table>
                <tbody>
                    <!-- Prototype -->
                    <tr>
                        <td class="sl-return-parameter__label border-t">
                            {{ t(`parameters.PROTOTYPE_CODE.label`) }}
                        </td>
                        <td class="sl-return-parameter__value border-t" :title="prototypeCode || prototypeCodeLegacy">
                            {{ prototypeLabel }}
                        </td>
                    </tr>

                    <!-- Parameters -->
                    <tr v-for="prop in article_data" :key="prop.parameterCode">
                        <!-- Parameter label -->
                        <td class="sl-return-parameter__label">
                            {{ getLabelByParameterCode(prop.parameterCode) }}
                        </td>
                        <!-- Parameter value (not empty) -->
                        <td v-if="prop.parameterValue !== null" class="sl-return-parameter__value">
                            <span v-html="pf(prop.parameterValue, prop.parameterCode, 'value')"></span>
                            <span v-if="getUnitByParameterCode(prop.parameterCode)" class="sl-return-parameter__unit">[&nbsp;{{ getUnitByParameterCode(prop.parameterCode) }}&nbsp;]</span>
                            <span v-if="getHelp(prop.parameterCode, prop.parameterValue)" class="ml-1 text-primary-400 text-xs">
                                ({{ getHelp(prop.parameterCode, prop.parameterValue) }})
                            </span>
                        </td>
                        <!-- Parameter value (empty) -->
                        <td v-else class="sl-return-parameter__value">
                            -
                        </td>
                    </tr>

                    <!-- Quantity -->
                    <tr>
                        <td class="sl-return-parameter__label">
                            {{ t(`parameters.quantity.label`) }}
                        </td>
                        <td class="sl-return-parameter__value">
                            {{ order.quantity }}
                        </td>
                    </tr>

                    <!-- Previous Order Reference -->
                    <tr v-if="order.previous_order_reference">
                        <td class="sl-return-parameter__label">
                            {{ t(`order.previous_order_reference.label`) }}
                        </td>
                        <td class="sl-return-parameter__value">
                            {{ order.previous_order_reference }}
                        </td>
                    </tr>

                    <!-- Previous Serial Number -->
                    <tr v-if="order.previous_order_reference">
                        <td class="sl-return-parameter__label">
                            {{ t(`order.previous_lens_sn.label`) }}
                        </td>
                        <td class="sl-return-parameter__value">
                            <lsn-loader-link
                                v-if="!previousSn"
                                :label="t('common.view_previous_sn')"
                                :is-loading="snLoading"
                                class=""
                                @click="loadPreviousSn"
                            />

                            <template v-else>
                                {{ previousSn }}
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>

        <!-- Second Column: Logistics / Return / Extra -->
        <div class="sl-return-details-col">

            <!-- Return  -->
            <div class="sl-return-details__title">
                {{ t('return_table.details.section.return.title') }}
            </div>

            <!-- Return (return_data) -->
            <div v-for="prop in return_data" :key="prop.parameterCode" class="sl-return-details-line">
                <div class="sl-return-details-line__label">
                    {{ t(`parameters.${prop.parameterCode}.label`) }}:
                </div>
                <div class="sl-return-details-line__value" v-html="pf(prop.parameterValue, prop.parameterCode, 'value')"></div>
            </div>

            <!-- Return ecom -->
            <div v-if="oReturn.ecom" class="sl-return-details-line">
                <div class="sl-return-details-line__label">
                    {{ t(`parameters.ecom.label`) }}:
                </div>
                <div class="sl-return-details-line__value">
                    {{ oReturn.ecom }}
                </div>
            </div>

            <!-- Return icom -->
            <div v-if="isStaff && oReturn.icom" class="sl-return-details-line">
                <div class="sl-return-details-line__label">
                    {{ t(`parameters.icom.label`) }}:
                </div>
                <div class="sl-return-details-line__value">
                    {{ oReturn.icom }}
                </div>
            </div>

            <!-- Return Questionnaire Answers -->
            <div class="sl-return-details__title">
                {{ t('return_table.details.section.return_questionnaire.title') }}
            </div>

            <!-- Return Questionnaire Answers -->
            <div v-for="retQuest in returnQuestionnaire" :key="retQuest.question_code" class="sl-return-details-line">
                <div class="sl-return-details-line__label">
                    {{ retQuest.question }}:
                </div>
                <div class="sl-return-details-line__value">
                    {{ retQuest.answer }}
                </div>
            </div>

            <!-- Complements  -->
            <template v-if="hasComplement">
                <div class="sl-return-details__title">
                    {{ t('order_table.details.section.complements.title') }}
                </div>

                <div v-if="order.extra.ext_ref" class="sl-return-details-line">
                    <div class="sl-return-details-line__label">
                        {{ t('parameters.ext_ref.label') }}:
                    </div>

                    <div class="sl-return-details-line__value">
                        {{ order.extra.ext_ref }}
                    </div>
                </div>
            </template>

            <!-- Delivery Note -->
            <div v-if="status.code === 'SHIPPED'" class="sl-order-details__section">
                <div class="sl-return-details__title">
                    {{ t('order_table.details.section.delivery_note.title') }}
                </div>

                <template v-if="deliveryNote">
                    <div class="sl-return-details-line">
                        <div class="sl-return-details-line__label">
                            {{ t('order_table.details.section.delivery_note.reference') }}:
                        </div>

                        <div class="sl-return-details-line__value">
                            {{ deliveryNote.reference_externe || deliveryNote.reference }}

                            <span>
                                |

                                <a
                                    v-tippy="{ arrow: true, theme: 'sl-light', animation : 'fade' }"
                                    :content="trackingStatus"
                                    :href="canTrack ? trackingUrl : '#!'"
                                    :target="canTrack ? '_blank' : '_self'"
                                    :class="{ 'lsn-table__link--disabled': !canTrack }"
                                    @click.stop
                                >
                                    {{ t('invoicing.delivery_notes.track') }}
                                </a>

                                |

                                <lsn-loader-link
                                    :label="t('common.download')"
                                    :is-loading="isFileDownloading"
                                    @click.stop="downloadFile"
                                />
                            </span>
                        </div>
                    </div>
                </template>

                <lsn-loader-link
                    v-else-if="deliveryNoteLoading"
                    label="load"
                    :is-loading="deliveryNoteLoading"
                    @click="loadDeliveryNote"
                />

                <div v-else class="sl-return-details-line">
                    <div class="sl-return-details-line__value">
                        &empty;
                    </div>
                </div>
            </div>

            <!-- Delete Return @todo -->
            <!-- <div v-if="canDeleteReturn" class="sl-order-details__section">
                <div class="sl-order-details__title">
                    {{ t('order_table.details.section.delete_order.title') }}
                </div>

                <lsn-loader-link
                    v-tippy="{ arrow: true, theme: 'sl-light', animation : 'fade' }"
                    :content="deletionRequested ? t('order_table.details.request_deletion.already_sent') : ''"
                    :label="t('order_table.details.request_deletion.button_label')"
                    :is-loading="isDeletionRequestLoading"
                    class="text-xs"
                    :class="deletionRequestLinkClass"
                    @click="requestDeletionLink_OnClick"
                />
            </div> -->
        </div>

        <!-- Third Column: Price Details -->
        <div v-can="'price:view'" class="sl-return-details-col">

            <!-- Credit -->
            <div class="sl-return-details__title">
                {{ t('return_table.details.section.lens_return_price.title') }}
            </div>

            <!-- Return Price -->
            <div class="sl-return-price-block">
                <div class="sl-return-price-line">
                    <!-- Return Policy Label or Price Message -->
                    <div v-if="!oReturn.price_information?.message" class="sl-return-price-line__label">
                        {{ oReturn.return_policy.label }}
                    </div>
                    <div v-else class="sl-return-price-line__label !text-red-600">
                        {{ oReturn.price_information.message }}
                    </div>
                    <!-- Return Price (calculated by Policy) -->
                    <div class="sl-return-price-line__price">
                        {{ oReturn.currency_code }}&nbsp;{{ f(oReturn.price, 'decimal:2') }}
                    </div>
                </div>

                <!-- Return Special Price -->
                <div v-if="oReturn.special_price" class="sl-return-price-line">
                    <div class="sl-return-price-line__label">
                        {{ t('return_table.details.special_price.label') }}
                    </div>

                    <div class="sl-return-price-line__price">
                        {{ oReturn.currency_code }}&nbsp;{{ f(oReturn.special_price, 'decimal:2') }}
                    </div>
                </div>
            </div>

            <!-- Order Price -->
            <div class="sl-return-details__title">
                {{ t('order_table.details.section.lens_price_details.title') }}
            </div>

            <div class="sl-return-price-block">
                <div class="sl-return-price-line">
                    <!-- Lens price -->
                    <div class="sl-return-price-line__label">
                        {{ getLabel(order) }}
                    </div>
                    <div class="sl-return-price-line__price">
                        <template v-if="!orderIsMoreExpensiveThanCatalogPrice">
                            {{ order.currency_code }}&nbsp;{{ f(order.price, 'decimal:2') }}
                        </template>
                    </div>
                </div>
                <!-- Lens supplement price -->
                <div v-for="supplement in order.supplements" :key="supplement.code" class="sl-return-price-line pl-4">
                    <div class="sl-return-price-line__label">
                        +&nbsp;{{ supplement.article_label || supplement.article_code }}
                    </div>
                    <div class="sl-return-price-line__price">
                        <template v-if="!orderIsMoreExpensiveThanCatalogPrice">
                            {{ supplement.currency_code }}&nbsp;{{ f(supplement.price, 'decimal:2') }}
                        </template>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- <teleport to="body">
        <delete-order-confirmation-modal
            :is-open="showDeleteOrderConfirmationModal"
            @cancel="showDeleteOrderConfirmationModal = false"
            @remove="requestDeletion()"
        />
    </teleport> -->

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script setup>

import { ref, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { omit } from 'lodash-es';
import { useToast } from 'vue-toastification';
import axios from '@/axios';
import $const from '@/constants/constants';
import { useStore } from 'vuex';
import { pf, f } from '@/utils/vue-formatter';
import LsnLoaderLink from '@/components/LsnLoaderLink.vue';
import useOrderTableComposable from '@/composables/OrderTableComposable';
import useFileDownloader from '@/composables/FileDownloader';
import useDeliveryNoteComposable from '@/composables/DeliveryNoteComposable';
import DeleteOrderConfirmationModal from '@/views/order/DeleteOrderConfirmationModal.vue';


// ------------------------------------------------------------ PROPS

const props = defineProps({
    oReturn:
    {
        type:     Object,
        required: true,
    },
});

// ------------------------------------------------------------ DATA

const snLoading           = ref(false);
const previousSn          = ref('');

const deliveryNoteLoading = ref(false);
const deliveryNote        = ref(null);

// const showDeleteOrderConfirmationModal = ref(false);

// // ------------------------------------------------------------ COMPOSABLES

const { t, te } = useI18n({ useScope: 'global' });
const store = useStore();

// FileDownloader
const { isFileDownloading, downloadFile } = useFileDownloader(
    () =>
    {
        const eid = store.state.account.cEntity.id;
        const documentId = deliveryNote.value.id;

        return `${import.meta.env.VITE_API_LOCATION}/api/delivery-note/${eid}/${documentId}/pdf`;
    },
    () => (deliveryNote.value.reference_externe || deliveryNote.value.reference) + '.pdf'
);

// DeliveryNoteComposable
const {
    canTrack,
    trackingStatus,
    trackingUrl,
} = useDeliveryNoteComposable({ deliveryNote });

// ------------------------------------------------------------ COMPUTED

const order = computed(() =>
{
    return props.oReturn.order;
});

const prototypeCode = computed(() =>
{
    return props.oReturn.order.article_data['PROTOTYPE_CODE'];
});

const prototypeCodeReference = computed(() =>
{
    return props.oReturn.order.article_data['PROTOTYPE_CODE_REFERENCE'];
});

const prototypeCodeLegacy = computed(() =>
{
    return props.oReturn.order.article_data['PROTOTYPE_CODE_LEGACY'];
});

const prototypeLabel = computed(() =>
{
    return store.state.prototypes.prototypes.find(p => p.code === prototypeCode.value)?.label
        || prototypeCode
        || prototypeCodeLegacy;
});

const article_data = computed(() =>
{
    // Remove some values
    const data = omit(props.oReturn.order.article_data, [
        'LENS_SIDE',
        'PROTOTYPE_CODE',
        'PROTOTYPE_CODE_LEGACY',
        'PATIENT_ID',
        'PATIENT_REFERENCE1',
        'PROTOTYPE_VERSION',
    ]);

    // Order values
    return collection(data);
});

const return_data = computed(() =>
{
    return collection(props.oReturn.order.return_data);
});

const returnQuestionnaire = computed(() =>
{
    const collection = [];

    // Order
    for(const retQuest of props.oReturn.return_questionnaire_answers)
    {
        const qtkey = `parameters.${retQuest.question_code}.label`;
        const atkey = `parameters.${retQuest.question_code}.values.${retQuest.answer}.label`;

        const question = te(qtkey) ? t(qtkey) : retQuest.question_code;
        const answer = te(atkey) ? t(atkey) : retQuest.answer;

        collection.push({
            question_code: retQuest.question_code,
            question:      question,
            answer:        answer,
        });
    }

    return collection;
});

const cEntity = computed(() =>
{
    return store.state.account.cEntity;
});

const status = computed(() =>
{
    return [].concat(props.oReturn.order.status).pop(); // last status
});

const orderIsMoreExpensiveThanCatalogPrice = computed(() =>
{
    if(props.oReturn.order.special_price === null)
    {
        return false;
    }

    return props.oReturn.order.special_price > props.oReturn.order.catalog_total;
});

// const canDeleteReturn = computed(() =>
// {
//     return false; // @todo
// });

const isStaff = computed(() =>
{
    return store.getters['account/isStaff'];
});

const hasComplement = computed(() =>
{
    return props.oReturn.order.extra.ext_ref;
});

// ------------------------------------------------------------ MOUNTED

onMounted(() =>
{
    if(props.oReturn.order.previous_order_reference)
    {
        loadPreviousSn();
    }

    if(status.value.code === $const.orders.status.SHIPPED)
    {
        loadDeliveryNote();
    }
});

// ------------------------------------------------------------ METHODS

function collection(data)
{
    const collection = [];

    // Order
    for(const key in data)
    {
        if(parseInt(store.getters['parameters/getProperty'](prototypeCode.value || prototypeCodeReference.value, key, 'has_access')))
        {
            collection.push({
                parameterCode:  key,
                parameterValue: data[key],
                parameterOrder: parseInt(store.getters['parameters/getProperty'](prototypeCode.value || prototypeCodeReference.value, key, 'sort_order')),
            });
        }
    }

    // Order values
    return collection.sort((a, b) => a.parameterOrder - b.parameterOrder);
}

function getLabel(line)
{
    return line.article.label || line.article.code || '';
};

function getLabelByParameterCode(parameterCode)
{
    return t(`parameters.${parameterCode}.label`);
};

function getUnitByParameterCode(parameterCode)
{
    return store.getters['parameters/getAttribute'](parameterCode, 'unit');
}

/**
 * Get the help message for a parameter's value,
 * or `null` if there is none.
 *
 * @param {string} code The code of the parameter.
 * @param {*} value     The value of the parameter.
 * @returns {?string}
 */
function getHelp(code, value)
{
    const tkey = `parameters.${code}.values.${value}.help`;

    if(te(tkey))
    {
        return t(tkey);
    }

    return null;
}

function loadPreviousSn()
{
    if(snLoading.value)
    {
        return;
    }

    snLoading.value = true;
    const previousRef = props.oReturn.order.previous_order_reference;
    axios.get(`/api/order/reference/${previousRef}`)
        .then(({ data: previousOrder }) =>
        {
            previousSn.value = previousOrder.article_data.LENS_SERIAL_NUMBER || '∅';
        })
        .catch(error =>
        {
            // todo: handle error
        })
        .finally(() =>
        {
            snLoading.value = false;
        });
}

function loadDeliveryNote()
{
    if(deliveryNoteLoading.value)
    {
        return;
    }

    deliveryNoteLoading.value = true;
    const eid = cEntity.value.id;
    const orderReference = props.oReturn.order.reference;
    axios.get(`/api/delivery-note/entity/${eid}/order/${orderReference}`)
        .then(response =>
        {
            deliveryNote.value = response.data;
        })
        .catch(error =>
        {
            // todo: handle error
        })
        .then(() =>
        {
            deliveryNoteLoading.value = false;
        });
}

</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.sl-return-details-col
{
    @apply w-1/3 pb-3 mr-4;
}

.sl-return-details-col:last-child
{
    @apply mr-0;
}

.sl-return-details__title
{
    @apply text-gray-700 text-sm mt-4 mb-1;
}

/*** Parameters ***/

.sl-return-parameter__label
{
    @apply border-b border-r border-gray-300 text-right text-xs text-gray-500 px-1;
}

.sl-return-parameter__value
{
    @apply border-b border-gray-300 text-left text-xs text-primary-500 px-1 tracking-wide;
}

.sl-return-parameter__unit
{
    @apply align-middle ml-1 mr-0.5 text-[8px] text-gray-400;
}

/*** Details ***/

.sl-return-details-line
{
    @apply flex flex-wrap w-full my-1 leading-4;
}

.sl-return-details-line__label
{
    @apply text-xs text-gray-500 mr-2;
}

.sl-return-details-line__value
{
    @apply text-xs text-primary-500 tracking-wide;
}

.sl-return-details-line__address
{
    @apply w-full text-xs text-primary-500 tracking-wide;
}

.sl-return-price-line
{
    @apply flex flex-wrap py-2 border-t border-gray-200;
}

.sl-return-price-block .sl-return-price-line:last-child
{
    @apply border-b;
}

.sl-return-price-line__label
{
    @apply grow text-xs text-gray-500;
}

.sl-return-price-line__price
{
    width: 100px;
    @apply text-xs text-primary-500 text-right;
}

</style>
