<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="lsn-table__line" :class="{ 'lsn-table__line--open': showDetails }">
        <div class="lsn-table__line-summary group" @click="toggleOpen">
            <!-- Checkbox (if selectable) -->
            <lsn-table-cell v-if="selectable" class="lsn-table__checkbox">
                <mdi-icon
                    :icon="isSelected ? $const.icons.mdiCheckboxMarkedOutline : $const.icons.mdiCheckboxBlankOutline"
                    class="lsn-table__checkbox-input"
                    :class="{ 'lsn-table__checkbox-input--checked': isSelected }"
                    @click.stop="isSelected = !isSelected"
                />
            </lsn-table-cell>

            <!-- Chevron (if openable) -->
            <lsn-table-cell v-if="openable" class="lsn-table__chevron">
                <mdi-icon icon="mdiChevronRight" />
            </lsn-table-cell>

            <!-- Line Content -->
            <slot name="summary" />
        </div>

        <div v-if="showDetails" class="lsn-table__line-details">
            <slot name="details" />
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import LsnTableCell from './LsnTableCell.vue';

export default
{
    components:
    {
        LsnTableCell,
    },

    props:
    {
        item:
        {
            type:     Object,
            required: true,
        },

        selectable:
        {
            type:    Boolean,
            default: false,
        },

        openable:
        {
            type:    Boolean,
            default: false,
        },
    },

    emits: ['selected', 'unselected'],

    data()
    {
        return {
            isSelected:  false,
            showDetails: false,
        };
    },

    watch:
    {
        isSelected(isSelected)
        {
            this.$emit(isSelected ? 'selected' : 'unselected', this.item.id);
        },
    },

    methods:
    {
        toggleOpen()
        {
            if(this.openable)
            {
                this.showDetails = !this.showDetails;
            }
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
.lsn-table__line
{
    @apply border-b border-primary-100;
}

.lsn-table__line--open
{
    @apply bg-gray-50;
}

.lsn-table__checkbox
{
    @apply flex justify-center items-center;
}

.lsn-table__checkbox-input
{
    @apply cursor-pointer inline-block -mt-px w-5 h-5 text-gray-500;
}

.lsn-table__checkbox-input--checked
{
    @apply text-primary-500;
}

.lsn-table__chevron > .mdi-icon
{
    margin-top: -2px;
    @apply inline-block align-middle w-4 h-4 rounded-full border border-primary-500 text-primary-300 transform;
}

.lsn-table__line--open > .lsn-table__line-summary > .lsn-table__chevron > .mdi-icon
{
    @apply rotate-90;
}


/* ------------------------------ Line Summary */

.lsn-table--openable > .lsn-table__body > .lsn-table__line > .lsn-table__line-summary
{
    @apply cursor-pointer;
}

// Default line layout:
//   - One fixed-width column for selection checkbox
//   - One fixed-width column for open/close toggle button
//   - All other columns distributed equally
.lsn-table__line-summary
{
    @apply py-2 items-center
        hover:bg-primary-10;

    display: grid;
    grid-template-columns: var(--checkbox-width) var(--open-toggle-width) repeat(auto-fit, minmax(0, 1fr));
    grid-template-rows: auto;
}

// If the table isn't selectable, there's no checkbox, so remove that fixed-width column.
.lsn-table:not(.lsn-table--selectable) > .lsn-table__body > .lsn-table__line > .lsn-table__line-summary
{
    grid-template-columns: var(--open-toggle-width) repeat(auto-fit, minmax(0, 1fr));
}

// If the table isn't openable, there's no chevron, so remove that fixed-width column.
.lsn-table:not(.lsn-table--openable) > .lsn-table__body > .lsn-table__line > .lsn-table__line-summary
{
    grid-template-columns: var(--checkbox-width) repeat(auto-fit, minmax(0, 1fr));
}

// If the table isn't neither selectable nor openable, there's no checkbox, so remove that fixed-width column.
.lsn-table:not(.lsn-table--selectable):not(.lsn-table--openable) > .lsn-table__body > .lsn-table__line > .lsn-table__line-summary
{
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.lsn-table__chevron
{
    @apply text-center;
}

.lsn-table a
{
    @apply text-primary-300 transition hover:text-primary-800;
}

.lsn-table .lsn-table__link--disabled
{
    @apply cursor-not-allowed text-gray-400 hover:text-gray-400;
}
</style>
