<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractField from './AbstractField.vue';

export default
{
    name: 'AbstractInlineField',

    extends: AbstractField,

    props:
    {
        columnIndex:
        {
            type:     Number,
            required: true,
        },
    },

    computed:
    {
        columnClasses()
        {
            const classes = ['sl-order-form__line-column-' + this.columnIndex];

            if(this.error)
            {
                classes.push('sl-order-form__line-column--error');
            }

            return classes;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
// ------------------------------------------------------------ WHOLE SECTION (LINE SIDE)

.sl-order-form__line.sl-order-form__inline
{
    @apply justify-center;
}

.sl-order-form__line-side.sl-order-form__inline-section
{
    @apply grid;

    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    align-items: unset;
}


// ------------------------------------------------------------ SECTION COLUMN

.sl-order-form__line-column
{
    @apply px-0 pt-1 bg-white;
}

.sl-order-form__line-column:focus-within
{
    @apply bg-primary-50;

    .sl-order-form__label
    {
        @apply text-primary-500;
    }
}

.sl-order-form__line-column--error
{
    @apply bg-red-100;
}

.sl-order-form__line-column--error:focus-within
{
    @apply bg-red-200;

    .sl-order-form__label
    {
        @apply text-red-600;
    }
}


// ---------------------------------------- Label

.sl-order-form__line-column .sl-order-form__label-wrapper
{
    @apply flex items-center text-xs;
}

// ---------------------------------------- Field

.sl-order-form__inline-section
{
    &.sl-order-form__line-side-right .sl-order-form__line-column
    {
        @apply border-r border-gray-200;
    }

    &.sl-order-form__line-side-left .sl-order-form__line-column
    {
        @apply border-l border-gray-200;
    }
}

.sl-order-form__inline-section .sl-order-form__line-column .sl-order-form__label-wrapper
{
    @apply py-0 w-full text-left;
}


// ------------------------------------------------------------ ERROR ICON

.sl-order-form__inline-section-error
{
    @apply cursor-help ml-1 text-red-500;
}


// ------------------------------------------------------------ VUE TIPPY SECTION FIELD ERROR

.tippy-box[data-theme~="sl-section-error"]
{
    @apply text-red-500;
}
</style>
