<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div v-click-outside="closeBurgerMenu" class="sl-burger-menu flex lg:hidden">
        <!-- Burger Menu Icon -->
        <div class="sl-navbar__icon-item" @click.prevent="isMenuOpen = !isMenuOpen">
            <mdi-icon icon="mdiMenu" />
        </div>

        <!-- Slider Menu -->
        <transition name="slide-left">
            <div v-if="isMenuOpen" class="sl-burger-menu__content">
                <router-link v-slot="{ navigate }" :to="{ name: 'my-account', params: $route.params }" custom>
                    <div class="sl-account cursor-pointer" @click="navigate">
                        <div class="sl-account__icon-wrapper">
                            <mdi-icon icon="mdiAccount" class="sl-account__icon" />
                        </div>

                        <div class="sl-account__name-wrapper">
                            <div class="sl-account__name">
                                {{ fullname }}
                            </div>

                        <!-- <div class="mt-1 text-primary-500 text-xs">
                            modifier
                        </div> -->
                        </div>
                    </div>
                </router-link>

                <!-- SwissLens Website -->
                <div class="sl-slide-menu__link-item">
                    <a href="https://www.swisslens.ch" target="_blank">
                        {{ $t('burger_menu.swisslens_website') }}

                        <mdi-icon icon="mdiOpenInNew" class="sl-slide-menu__link-item-icon" />
                    </a>
                </div>

                <!-- new order -->
                <router-link v-slot="{ navigate, isActive }" :to="{ name: 'order-lenses', params: $route.params }" custom>
                    <div v-can="'order:place'" class="sl-slide-menu__link-item" :class="{ 'sl-side-menu__item--active': isActive }" @click="navigate">
                        {{ $t('burger_menu.new_order') }}
                    </div>
                </router-link>

                <!-- orders -->
                <router-link v-slot="{ navigate, isActive }" :to="{ name: 'order-list', params: $route.params }" custom>
                    <div v-can="'order:view'" class="sl-slide-menu__link-item" :class="{ 'sl-side-menu__item--active': isActive }" @click="navigate">
                        {{ $t('burger_menu.orders') }}
                    </div>
                </router-link>

                <!-- new return -->
                <router-link v-slot="{ navigate, isActive }" :to="{ name: 'return-lenses', params: $route.params }" custom>
                    <div v-can="'return:create'" class="sl-slide-menu__link-item" :class="{ 'sl-side-menu__item--active': isActive }" @click="navigate">
                        {{ $t('burger_menu.return_an_article') }}
                    </div>
                </router-link>

                <!-- returns -->
                <router-link v-slot="{ navigate, isActive }" :to="{ name: 'return-list', params: $route.params }" custom>
                    <div v-can="'return:view'" class="sl-slide-menu__link-item" :class="{ 'sl-side-menu__item--active': isActive }" @click="navigate">
                        {{ $t('burger_menu.returns') }}
                    </div>
                </router-link>

                <!-- invoicing -->
                <router-link v-slot="{ navigate, isActive }" :to="{ name: 'invoice-list', params: $route.params }" custom>
                    <div v-can="'invoice:view'" class="sl-slide-menu__link-item" :class="{ 'sl-side-menu__item--active': isActive }" @click="navigate">
                        {{ $t('burger_menu.invoicing') }}
                    </div>
                </router-link>

                <!-- price list -->
                <router-link v-slot="{ navigate, isActive }" :to="{ name: 'price-list', params: $route.params }" custom>
                    <div v-can="'price-list:view'" class="sl-slide-menu__link-item" :class="{ 'sl-side-menu__item--active': isActive }" @click="navigate">
                        {{ $t('burger_menu.price_list') }}
                    </div>
                </router-link>

                <!-- my account -->
                <router-link v-slot="{ navigate, isActive }" :to="{ name: 'my-account', params: $route.params }" custom>
                    <div class="sl-slide-menu__link-item" :class="{ 'sl-side-menu__item--active': isActive }" @click="navigate">
                        {{ $t('burger_menu.my_account') }}
                    </div>
                </router-link>

                <!-- company -->
                <router-link v-slot="{ navigate, isActive }" :to="{ name: 'contact-details', params: $route.params }" custom>
                    <div v-can="'contact-details:edit'" class="sl-slide-menu__link-item" :class="{ 'sl-side-menu__item--active': isActive }" @click="navigate">
                        {{ $t('burger_menu.contact_details') }}
                    </div>
                </router-link>

                <!-- Bottom Menu Items -->
                <div class="absolute w-full bottom-0">
                    <!-- logout -->
                    <div class="sl-slide-menu__link-item border-t text-center" @click="logout">
                        {{ $t('burger_menu.logout') }}
                    </div>
                </div>

            </div>
        </transition>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'MainMenuBurger',

    data()
    {
        return {
            isMenuOpen: false,
        };
    },

    computed:
    {
        fullname()
        {
            const cUser = this.$store.state.account.cUser;
            if(!cUser.last_name || !cUser.first_name)
            {
                return cUser.email;
            }

            return cUser.first_name + ' ' + cUser.last_name;
        },
    },

    methods:
    {
        closeBurgerMenu()
        {
            if(this.isMenuOpen)
            {
                this.isMenuOpen = false;
            }
        },

        logout()
        {
            this.$store.dispatch('auth/logout');
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
$navbar-height: 50px;

.sl-burger-menu
{
    @apply relative;
}

.sl-burger-menu__content
{
    @apply fixed left-0 bottom-0 z-50 shadow-2xl w-[300px] bg-white;

    top: $navbar-height;
}

// ------------------------------------------------------------ ACCOUNT PART

.sl-account
{
    @apply flex items-center border-b border-gray-300 py-4 w-full bg-gray-100;
}

.sl-account__icon-wrapper
{
    @apply w-[70px];
}

.sl-account__icon
{
    @apply mx-auto w-[50px] h-[50px] text-gray-500;
}

.sl-account__name-wrapper
{
}

.sl-account__name
{
    @apply text-gray-600 text-sm;
}


// ------------------------------------------------------------ MENU LINK

.sl-slide-menu__link-item
{
    @apply cursor-pointer block border-b border-primary-100 pl-5 py-4 text-primary-500 text-sm;
}

.sl-slide-menu__link-item:last-child
{
    @apply border-b-0;
}

.sl-slide-menu__link-item:hover
{
    @apply bg-primary-100;
}

.sl-slide-menu__link-item-icon
{
    @apply inline-block align-middle -mt-1 ml-2.5 w-5 h-5 text-gray-400;
}
</style>
