<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="sl-form-dual__line-side" :class="`sl-form-dual__line-side-${side}`">

        <!-- Helper (grey vertical band) -->
        <div class="sl-form-dual__line-band-wrapper">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="sl-form-dual__line-band-content" v-html="currentField.getHelper()"></div>
        </div>

        <!-- Error Wrapper -->
        <div class="sl-form-dual__line-content-wrapper" :class="{ 'sl-form-dual__line-content-wrapper--error': currentField.getError() }">

            <!-- Prefix -->
            <div v-if="currentField.getPrefix() !== null" class="sl-form-dual__prefix-wrapper">
                <div class="sl-form-dual__prefix" v-html="currentField.getPrefix()"></div>
            </div>

            <!-- Field Wrapper -->
            <div class="sl-form-dual__field-wrapper w-16">
                <input
                    v-model.lazy="currentValue"
                    class="sl-form-dual__input-number"
                    type="text"
                    :name="`${side}-special_price`"
                    :tabindex="tabIndex"
                    autocomplete="off"
                    @focus="onFocus"
                    @blur="onBlur"
                >
            </div>

            <!-- Suffix -->
            <div v-if="currentField.getSuffix()" class="sl-form-dual__suffix-wrapper">
                <div class="sl-form-dual__suffix" v-html="currentField.getSuffix()"></div>
            </div>

            <!-- Fillers -->
            <div class="sl-form-dual__suffix-wrapper flex">
                <div
                    v-for="filler in fillers"
                    :key="filler.val"
                    class="flex items-center justify-center h-[30px] w-[38px] cursor-pointer hover:text-primary-500"
                    @click="fill(filler.val)"
                >
                    <div class="text-xs" v-html="filler.label"></div>
                </div>
            </div>

            <!-- Supplement -->
            <div v-if="supplement" class="sl-form-dual__supplement-wrapper">
                <div class="sl-form-dual__supplement">
                    {{ supplement }}
                </div>
            </div>

            <!-- Note -->
            <div v-if="currentField.getNote()" class="sl-form-dual__note-wrapper">
                <div class="sl-form-dual__note">
                    {{ currentField.getNote() }}
                </div>
            </div>

            <!-- Error -->
            <div v-if="currentField.getError()" class="sl-form-dual__error-wrapper">
                <div class="sl-form-dual__error">
                    {{ currentField.getError() }}
                </div>
            </div>

        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import Numbers from '@/utils/Numbers';
//import FormFieldAbstract from '@/components/forms/form-dual/form-sections/form-lines/FormFieldAbstract';

export default
{
    name: 'FormFieldSpecialPrice',

    //extends: FormFieldAbstract,

    props:
    {
        index:
        {
            type:     Number,
            required: true,
        },

        side:
        {
            type:     String,
            required: true,
        },

        field:
        {
            type:     Object,
            required: true,
        },
    },

    emits: ['update'],

    data()
    {
        return {
            isTouched:    false,
            isFocused:    false,
            currentField: this.field,
            fillers:      [
                { label: '25%',     val: 0.25 },
                { label: '50%',     val: 0.50 },
                { label: '70%',     val: 0.70 },
                { label: '80%',     val: 0.80 },
                { label: '100%',    val: 1.00 },
                { label: '&empty;', val: -1.0 },
            ],
        };
    },

    computed:
    {
        currentValue:
        {
            get()
            {
                if(this.isFocused)
                {
                    return this.currentField.getValue();
                }
                else
                {
                    return this.currentField.getFormattedValue();
                }
            },

            set(value)
            {
                this.isTouched = true;

                this.currentField.setValue(value);

                this.$emit('update', this.currentField.getCode(), this.currentValue, this.side);
            },
        },

        tabIndex()
        {
            return this.side === 'left' ? 2 : 1;
        },

        supplement()
        {
            // if(this.parameter.priced_article_code)
            // {
            //     const article = this.$store.getters['catalog/getByCode'](this.parameter.priced_article_code);
            //     if(article)
            //     {
            //         return `+ ${this.max2decimals(article.price)} ${article.currency_code}`;
            //     }
            // }

            return null;
        },

        name()
        {
            return this.side + '-' + this.currentField.getCode();
        },
    },

    watch:
    {
        field(field)
        {
            this.currentField = field;
        },
    },

    methods:
    {
        onFocus()
        {
            this.isFocused = true;
        },

        onBlur()
        {
            this.isFocused = false;
            this.isTouched = true;
        },

        fill(val)
        {
            if(val == -1)
            {
                this.currentValue = null;
            }
            else
            {
                const ret = this.$store.getters['lensReturn/getReturn'](this.side);
                this.currentValue = Numbers.round(ret.getOrder().total * val, 2);
            }
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">



</style>
