<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="lsn-table" :class="cssClass">

        <!-- Embedded Table Header -->
        <div v-if="embedded" class="lsn-subtable__header">
            <span class="lsn-subtable__caption">
                <slot name="subtable-caption" />
            </span>

            <span class="lsn-subtable__lines-count">
                {{ data.length }}
            </span>
        </div>

        <!-- Table Header -->
        <div class="lsn-table__header">
            <div v-if="selectable" class="lsn-table-header__chevron">
                <!-- Just for spacing -->
            </div>

            <div v-if="openable" class="lsn-table-header__chevron">
                <!-- Just for spacing -->
            </div>

            <slot name="header" />
        </div>

        <!-- Table Body (lines) -->
        <div class="lsn-table__body">
            <lsn-table-line
                v-for="(item, index) in data"
                :key="index"
                :item="item"
                :openable="openable"
                :selectable="selectable"
                @selected="onLineSelected"
                @unselected="onLineUnselected"
            >
                <template #summary>
                    <slot name="summary" :item="item" />
                </template>

                <template #details>
                    <slot name="details" :item="item" />
                </template>
            </lsn-table-line>
        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import LsnTableLine from './LsnTableLine.vue';

export default
{
    name: 'LsnTable',

    components:
    {
        LsnTableLine,
    },

    props:
    {
        data:
        {
            type:     Array,
            required: true,
        },

        openable:
        {
            type:    Boolean,
            default: false,
        },

        selectable:
        {
            type:    Boolean,
            default: false,
        },

        embedded:
        {
            type:    Boolean,
            default: false,
        },

        selected:
        {
            type: Array,
            default()
            {
                return [];
            },
        },
    },

    emits: ['update:selected'],

    data()
    {
        return {
            selectedIds: this.selected,
        };
    },

    computed:
    {
        cssClass()
        {
            return {
                'lsn-table--openable':   this.openable,
                'lsn-table--selectable': this.selectable,
                'lsn-table--embedded':   this.embedded,
            };
        },
    },

    watch:
    {
        selectedIds:
        {
            deep: true,
            handler()
            {
                this.$emit('update:selected', this.selectedIds);
            },
        },
    },

    methods:
    {
        onLineSelected(id)
        {
            this.selectedIds.push(id);
        },

        onLineUnselected(id)
        {
            let index = this.selectedIds.indexOf(id);

            if(index !== -1)
            {
                this.selectedIds.splice(index, 1);
            }
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

.lsn-table
{
    --checkbox-width:    30px;
    --open-toggle-width: 30px;
}

.lsn-table__header
{
    @apply py-2 border-b border-primary-100 items-center text-primary-500;

    display: grid;
    grid-template-columns: var(--checkbox-width) var(--open-toggle-width) repeat(auto-fit, minmax(0, 1fr));
    grid-template-rows: auto;
}

.lsn-table:not(.lsn-table--openable) > .lsn-table__header
{
    /* If the table isn't openable, there's no chevron, so no fixed first-column */
    grid-template-columns: var(--checkbox-width) repeat(auto-fit, minmax(0, 1fr));
}

.lsn-table:not(.lsn-table--selectable) > .lsn-table__header
{
    /* If the table isn't openable, there's no chevron, so no fixed first-column */
    grid-template-columns: var(--open-toggle-width) repeat(auto-fit, minmax(0, 1fr));
}

.lsn-table:not(.lsn-table--selectable):not(.lsn-table--openable) > .lsn-table__header
{
    /* If the table isn't openable, there's no chevron, so no fixed first-column */
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.lsn-table__body
{
    @apply text-gray-500 text-sm;
}

/* Custom elements */
.lsn-table__tag
{
    @apply inline-block rounded-full px-4 py-1 bg-primary-10 text-primary-300
        group-hover:bg-primary-100;
}

/* --------------- EMBEDDED / SUB TABLE */
.lsn-subtable
{
    @apply border border-primary-100;
}

.lsn-subtable__header
{
    @apply p-2 border-b border-primary-100 bg-primary-10 text-primary-500 font-bold;
}

.lsn-subtable__caption
{
    @apply inline-block align-middle;
}

.lsn-subtable__lines-count
{
    @apply inline-block align-middle rounded-full px-2 py-1 ml-3 bg-primary-100 text-xs text-primary-300 font-normal;
}

</style>
