import { i18n } from '@/main';
import Formatters from '@/utils/Formatters';

export default class FormField
{
    parameter = null;

    constructor(parameter = null)
    {
        this.parameter = parameter;
    }

    getCode()
    {
        return this.parameter.code;
    }

    getValue()
    {
        return this.parameter.getValue();
    }

    getFormattedValue()
    {
        return this.formatValue(this.parameter.getValue());
    }

    formatValue(value)
    {
        if(typeof this.parameter.formatters === 'string')
        {
            const formatters = this.parameter.formatters.split('|');

            for(const formatter of formatters)
            {
                const parts = formatter.split(':');
                const fn    = parts[0];
                const args  = [];

                if(parts[1])
                {
                    args.push(...parts[1].split(','));
                }

                args.push({ i18n, pcode: this.parameter.code, context: 'value' });
                args.unshift(value);

                value = Formatters[fn].apply(null, args);
            }
        }

        return value;
    }

    getLabel()
    {
        return this.parameter.form_label;
    }

    getPrefix()
    {
        return this.parameter.prefix;
    }

    getSuffix()
    {
        return this.parameter.suffix;
    }

    getUnit()
    {
        return this.parameter.unit;
    }

    getFormDisplay()
    {
        return this.parameter.form_display;
    }

    getFormSplitDisplay()
    {
        return this.parameter.form_splitted_display;
    }

    getError()
    {
        return this.parameter.error;
    }

    getHelper()
    {
        return this.parameter.help;
    }

    getPricedArticleCode()
    {
        return this.priced_article_code;
    }

    getItems()
    {
        return this.parameter.list_items;
    }

    getNote()
    {
        return this.parameter.note;
    }

    getDefinedValueLabel()
    {
        return this.parameter.defined_value_label;
    }

    getDefinedValue()
    {
        return this.parameter.defined_value;
    }

    setValue(value)
    {
        this.parameter.setValue(value);
    }

    isEnabled()
    {
        return this.parameter.is_enabled;
    }
}
