// ===============
// Main Vuex Store
// ===============

import axios from '@/axios';
import constants from '@/constants/constants';
import Cookie from '@/utils/Cookie';
import { createStore } from 'vuex';
import AjaxStack from 'ajaxstack';
import { useRouter } from 'vue-router';
import { getApplicationConfigurations } from '@/vue-configurations';

// Modules
import { setupAccountModule } from './account';
import { setupAuthModule } from './auth';
import { setupBasketModule } from './basket';
import setupCalculatorsModule from './calculators';
import { setupCatalogModule } from './catalog';
import forms from './forms';
import { setupLensOrderModule } from './lensOrder';
import { setupLensReturnModule } from './lensReturn';
import { setupNotificationsModule } from './notifications';
import orders from './orders';
import overlay from './overlay';
import { setupParametersModule } from './parameters';
import patients from './patients';
import prototypes from './prototypes';
import returns from './returns';

// Actions called during entity initialization (on entity select/change).
const entityInitializationActions = [
    { action: 'catalog/fetchCatalog' },
    { action: 'forms/fetchAllOfg' },
    { action: 'prototypes/fetchAll', after: ['catalog/fetchCatalog'] },
    { action: 'parameters/fetchGlobalProperties', after: ['prototypes/fetchAll'] },
    { action: 'basket/load', after: ['catalog/fetchCatalog'] },
    { action: 'parameters/fetchAttributes', after: ['prototypes/fetchAll'] },
    { action: 'parameters/fetchTranslations' },
];

const $aconfig = getApplicationConfigurations();

export const DEFAULT_MAIN_MENU_ITEM = 'new_order';

export function setupStore(i18n, VueHoneybadger)
{
    const store = createStore({
        // ------------------------------------------------------------ MODULES

        modules:
        {
            account:       setupAccountModule(VueHoneybadger),
            auth:          setupAuthModule(),
            basket:        setupBasketModule(i18n),
            calculators:   setupCalculatorsModule(i18n),
            catalog:       setupCatalogModule(VueHoneybadger),
            forms,
            lensOrder:     setupLensOrderModule(i18n, VueHoneybadger),
            lensReturn:    setupLensReturnModule(i18n, VueHoneybadger),
            notifications: setupNotificationsModule(i18n),
            orders,
            overlay,
            parameters:    setupParametersModule(i18n),
            patients,
            prototypes,
            returns,
        },


        // ------------------------------------------------------------ STATE

        state()
        {
            // Retrieve persistent state from cookies
            const isCookiesUsageAcknowledged = Cookie.getItem('cookiesUsageAcknowledged') === '1';

            return {
                ajaxErrorMessage:   '',
                ajaxErrorModalOpen: false,
                isCookiesUsageAcknowledged,
                cMainMenuElement:   null,
                pricesVisible:      true,
            };
        },


        // ------------------------------------------------------------ MUTATIONS

        mutations:
        {
            /* ---------- Application configurations ---------- */

            /**
             * Set global application configurations (from app_config table).
             *
             * @param {Object} state
             * @param {Object} configurations
             */
            setApplicationConfigurations(state, configurations)
            {
                $aconfig.initialize(configurations);
            },

            setCookiesUsageAcknowledged(state, acknowledged)
            {
                state.isCookiesUsageAcknowledged = !!acknowledged;
            },

            /* ---------- Ajax Errors ---------- */

            /**
             * Set global ajax error message to be displayed in a modal.
             *
             * @param {Object} state
             * @param {String} ajaxErrorMessage
             */
            setAjaxErrorMessage(state, ajaxErrorMessage)
            {
                state.ajaxErrorMessage = ajaxErrorMessage;
            },

            /**
             * Open or close the ajax error modal message.
             *
             * @param {Object} state
             * @param {Boolean} status `true` to open, `false` to close.
             */
            setAjaxErrorModalStatus(state, status)
            {
                state.ajaxErrorModalOpen = !!status;
            },

            setCurrentMainMenuElement(state, element)
            {
                state.cMainMenuElement = null;
                state.cMainMenuElement = element;
            },

            setPricesVisibility(state, visible)
            {
                state.pricesVisible = visible;
            },
        },


        // ------------------------------------------------------------ ACTIONS

        actions:
        {
            preInitialize({ dispatch })
            {
                const promises = [
                    dispatch('account/fetchConnectedUser'),
                    dispatch('account/fetchUserEntitiesCount'),
                    dispatch('fetchApplicationConfigurations'),
                    // dispatch('auth/autoRefreshAccessToken'),
                ];

                return Promise.all(promises)
                    .then(([connectedUser, entitiesCount, appConfig]) =>
                    {
                        if(entitiesCount > 1 && entitiesCount <= constants.entities.SWITCHER_THRESHOLD)
                        {
                            dispatch('account/fetchUserEntities');
                        }

                        return dispatch('auth/checkIdle');
                    });
            },

            initialize({ dispatch }, eid)
            {
                return dispatch('account/fetchCurrentEntity', eid)
                    .then(entity =>
                    {
                        const initEntityAjaxStack = new AjaxStack(entityInitializationActions, dispatch, { concurrency: 3 });

                        return initEntityAjaxStack.run({});
                    })
                    .catch(error =>
                    {
                        if(error.toJSON?.().status === 403)
                        {
                            // Forbidden entity access => redirect to entity-switch
                            console.log('Forbidden entity access', eid);
                            useRouter().push({ name: 'entities-switch' });
                        }
                        else
                        {
                            VueHoneybadger.notify(error);
                        }
                    });
            },

            onBeforeEntitySwitch({ dispatch, rootState })
            {
                // Clear patients store
                dispatch('patients/clearAll');

                // Remove notifications
                [...rootState.notifications.band, ...rootState.notifications.tray].forEach(notification =>
                {
                    if(notification.keepOnEntitySwitch)
                    {
                        return;
                    }

                    dispatch('notifications/removeBandNotification', notification.id);
                });
            },

            setCookiesUsageAcknowledged({ commit }, acknowledged)
            {
                return new Promise((resolve, reject) =>
                {
                    const expiration = new Date();
                    expiration.setFullYear(expiration.getFullYear() + 1); // cookie expires in 1 year

                    Cookie.setItem('cookiesUsageAcknowledged', '1', expiration, '/');

                    commit('setCookiesUsageAcknowledged', acknowledged);
                    resolve(acknowledged);
                });
            },

            /* ---------- application configurations ---------- */

            fetchApplicationConfigurations({ commit })
            {
                return axios.get('/api/application-configurations')
                    .then(({ data: configurations }) =>
                    {
                        const aconfig = {};
                        for(const config of configurations)
                        {
                            aconfig[config.ckey] = config.cvalue;
                        }
                        commit('setApplicationConfigurations', aconfig);
                    })
                    .catch(error =>
                    {
                        console.log(error);
                    });
            },

            setAjaxErrorMessage({ commit }, message)
            {
                commit('setAjaxErrorMessage', message);
            },

            openAjaxErrorModal({ commit })
            {
                commit('setAjaxErrorModalStatus', true);
            },

            closeAjaxErrorModal({ commit })
            {
                commit('setAjaxErrorModalStatus', false);
            },

            setCurrentMainMenuElement({ commit }, element)
            {
                if(element !== null && !element instanceof HTMLElement)
                {
                    return Promise.reject('Invalid main menu element');
                }

                commit('setCurrentMainMenuElement', element);

                return Promise.resolve(element);
            },

            togglePricesVisibility({ commit, state })
            {
                const visible = !state.pricesVisible;
                commit('setPricesVisibility', visible);

                return Promise.resolve(visible);
            },
        },
    });

    return store;
};
