<template>
    <div class="h-[150px]">
        <canvas ref="graphArea" class="graph-area"></canvas>
    </div>
</template>

<script setup>
/**
 * @typedef {import('@/composables/GraphComposable.js').Surface}  Surface
 */

import {
    computed,
    nextTick,
    onMounted,
    onUnmounted,
    onUpdated,
    ref,
    watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Chart from 'chart.js/auto';
import useGraphComposable from '@/composables/GraphComposable';
import useFittingComposable from '@/composables/FittingComposable';
import { rgb2hsl } from '@/utils/Colors';


// ------------------------------------------------------------ CONSTANTS

const rgbRegex  = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/;
const rgbaRegex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*([\d\.]+)\)$/;


// ------------------------------------------------------------ COMPOSABLES

const { t } = useI18n({ useScope: 'global' });

const {
    getMeridian,
    getApexAlt360,
    getAllFittingColors,
} = useGraphComposable();

const { getLensLetter } = useFittingComposable();


// ------------------------------------------------------------ PROPS

/**
 * @type {{
 *     corneaPoints:      Surface,
 *     backSurfacePoints: Array<Surface|undefined>,
 *     viewingAngle:      number,
 *     showAngle2:        boolean,
 *     dtot:              number[],
 *     horizontalScale:   {},
 * }}
 */
const props = defineProps({
    corneaPoints:
    {
        type:     Array,
        required: true,
    },

    backSurfacePoints:
    {
        type:     Array,
        required: true,
    },

    viewingAngle:
    {
        type:    Number,
        default: NaN,
    },

    showAngle2:
    {
        type:    Boolean,
        default: false,
    },

    dtot:
    {
        type:     Array,
        required: true,
    },

    horizontalScale:
    {
        type:    Object,
        default: () => ({}),
    },

    minTear:
    {
        type:     Number,
        required: true,
    },
});


// ------------------------------------------------------------ DATA

/**
 * The `canvas` DOM element.
 * @type {import('vue').Ref<HTMLCanvasElement>}
 */
const graphArea = ref(null);

/**
 * The Chart.js instance.
 * @type {?Chart}
 */
let graph = null;

/**
 * The maximum difference in elevation between the cornea and the lens, for each
 * fitting.
 * @type {import('vue').Ref<number[]>}
 */
const apex = ref([]);

/** Whether the graph is currently being destroyed. */
const destroying = ref(false);

/** Hack to force refresh computed tear film. */
const refreshTearFilm = ref(0);

/**
 * The colors of the fittings.
 * @type {import('vue').Ref<string[]>}
 */
const fittingColors = ref([]);


// ------------------------------------------------------------ COMPUTED

/** The second viewing angle. */
const viewingAngle2 = computed(() => (props.viewingAngle + 90) % 360);

/** The tear film datasets, one for each fitting. */
const tearFilm = computed(() =>
{
    refreshTearFilm.value;
    return getTearFilm(props.viewingAngle);
});

/** The tear film datasets on the 2nd viewing angle, one for each fitting. */
const tearFilm2 = computed(() =>
{
    refreshTearFilm.value;
    return getTearFilm(viewingAngle2.value);
});

/**
 * The diameter of the corneal zone.
 */
const cornealDiameter = computed(() => props.corneaPoints[0].zones.CORNEAL.dia);


// ------------------------------------------------------------ WATCHERS

watch(() => props.corneaPoints, setApex);
watch(() => props.backSurfacePoints, setApex);

watch(() => [props.showAngle2, tearFilm.value, tearFilm2.value], async() =>
{
    if(!graph)
    {
        return;
    }

    graph.data.datasets = [];
    for(let id = 0; id < tearFilm.value.length; id++)
    {
        const letter = getLensLetter(id);

        if(tearFilm.value[id] && tearFilm.value[id].length > 0)
        {
            graph.data.datasets.push({
                code:             `TEAR_FILM_${letter}`,
                lensId:           letter,
                angle:            props.viewingAngle,
                borderColor:      fittingColors.value[id],
                backgroundColor:  rgbToRgba(fittingColors.value[id], 0.25),
                fill:             true,
                pointRadius:      0,
                pointHitRadius:   2,
                pointHoverRadius: 0,
                showLine:         true,
                borderJoinStyle:  'bevel',
                borderWidth:      1,
                data:             tearFilm.value[id],
            });
        }

        if(props.showAngle2)
        {
            if(tearFilm2.value[id] && tearFilm2.value[id].length > 0)
            {
                const { r, g, b } = parseRgb(fittingColors.value[id]);
                let { h, s, l } = rgb2hsl(r, g, b);
                h = (h + 20) % 360;
                l = Math.min(l + 10, 100);
                const color2 = `hsl(${h}, ${s}%, ${l}%)`;
                const color2a = `hsla(${h}, ${s}%, ${l}%, 0.20)`;

                graph.data.datasets.push({
                    code:             `TEAR_FILM_${letter}2`,
                    lensId:           letter,
                    angle:            viewingAngle2.value,
                    borderColor:      color2,
                    backgroundColor:  color2a,
                    fill:             true,
                    pointRadius:      0,
                    pointHitRadius:   2,
                    pointHoverRadius: 0,
                    showLine:         true,
                    borderJoinStyle:  'bevel',
                    borderWidth:      1,
                    data:             tearFilm2.value[id],
                });
            }
        }
    }

    await nextTick();
    graph.update();
}, { deep: true });


// ------------------------------------------------------------ METHODS

/**
 * Initialize the graph instance.
 */
function initGraph()
{
    // Initialize the graph instance
    if(!graphArea.value)
    {
        console.warn('Graph area is unavailable!');
        return;
    }

    if(graph !== null)
    {
        graph.destroy();
    }

    const graphOptions = {
        type: 'scatter',
        options:
        {
            animation:           false,
            responsive:          true,
            maintainAspectRatio: false,
            plugins:
            {
                legend:
                {
                    display: false,
                },
                tooltip:
                {
                    mode:      'index',
                    intersect: false,
                    footerFont:
                    {
                        weight: 'normal',
                    },
                    callbacks:
                    {
                        title(tooltipItems)
                        {
                            const x = tooltipItems[0]?.raw.x ?? 0;
                            const angle = x >= 0
                                ? props.viewingAngle
                                : (props.viewingAngle + 180) % 360;

                            const dia = Math.abs(x);
                            const kSag = getMeridian(props.corneaPoints, angle)
                                .sag(dia) * 1000;

                            const parts = [];
                            if(!props.showAngle2)
                            {
                                parts.push(`${angle}°`);
                            }
                            parts.push(`DIA: ${dia.toFixed(2)} mm`);

                            return parts.join(' · ');
                        },

                        label(tooltipItem)
                        {
                            const dataset = tooltipItem.chart.data
                                .datasets[tooltipItem.datasetIndex];
                            const id = dataset.lensId;
                            let label = t('common.tear_film') + ' ' +
                                getLensLetter(id);
                            if(props.showAngle2)
                            {
                                label += ' (' +  dataset.angle + '°)';
                            }

                            const x = tooltipItem.raw.x;
                            const y = tooltipItem.raw.y.toFixed(0);

                            return `${label}: ${y} µm`;
                        },

                        footer(tooltipItems)
                        {
                            const x = tooltipItems[0]?.raw.x ?? 0;
                            const angle = x >= 0
                                ? props.viewingAngle
                                : (props.viewingAngle + 180) % 360;

                            const dia = Math.abs(x);
                            const kSag = getMeridian(props.corneaPoints, angle)
                                .sag(dia) * 1000;

                            return `KSAG: ${kSag.toFixed(0)} µm`;
                        },
                    },
                },
            },
            scales:
            {
                y:
                {
                    min: 0,
                },
            },
        },
        data:
        {
            datasets: [],
        },
    };
    graph = new Chart(graphArea.value, graphOptions);
}

/**
 * Properly dipose of the graph instance.
 */
function destroyGraph()
{
    if(destroying.value || !graph)
    {
        return;
    }

    destroying.value = true;
    graph.destroy();
    graph = null;
    destroying.value = false;
}

/**
 * Calculate and memorize the apex of each fitting.
 *
 * @returns {number[]}
 */
function setApex()
{
    if(!props.corneaPoints.length || !props.backSurfacePoints.length)
    {
        apex.value = [];
    }

    const out = [];
    for(let id = 0; id < props.backSurfacePoints.length; id++)
    {
        const backSurfacePoints = props.backSurfacePoints[id];
        if(!backSurfacePoints?.length)
        {
            continue;
        }

        out[id] = getApexAlt360(props.corneaPoints, backSurfacePoints);
    }

    apex.value = out;

    // Force refresh computed tear film
    refreshTearFilm.value++;
}

/**
 * Calculate the tear films of all fittings at a given angle.
 *
 * @param {number} angle
 */
function getTearFilm(angle)
{
    const oppositeAngle = (angle + 180) % 360;

    if(props.backSurfacePoints.length === 0 || isNaN(angle))
    {
        return [];
    }

    const out = [];
    for(let id = 0; id < props.backSurfacePoints.length; id++)
    {
        const backSurfacePoints = props.backSurfacePoints[id];
        if(!backSurfacePoints || backSurfacePoints.length === 0)
            continue;

        if(isNaN(apex.value[id]))
            continue;

        const kMeridianPos = getMeridian(props.corneaPoints, angle);
        if(kMeridianPos === null)
            continue;

        const kMeridianNeg = getMeridian(props.corneaPoints, oppositeAngle);
        if(kMeridianNeg === null)
            continue;

        const clMeridianPos = getMeridian(backSurfacePoints, angle);
        if(clMeridianPos === null)
            continue;

        const clMeridianNeg = getMeridian(backSurfacePoints, oppositeAngle);
        if(clMeridianNeg === null)
            continue;

        if(kMeridianPos.resolution !== kMeridianNeg.resolution)
        {
            throw new Error(
                `Incompatible resolutions for K meridians at ${angle}°/${oppositeAngle}°: ` +
                `${kMeridianPos.resolution} != ${kMeridianNeg.resolution}`
            );
        }

        if(clMeridianPos.resolution !== clMeridianNeg.resolution)
        {
            throw new Error(
                `Incompatible resolutions for CL meridians at ${angle}°/${oppositeAngle}°: ` +
                `${clMeridianPos.resolution} != ${clMeridianNeg.resolution}`
            );
        }

        out[id] = [];
        const maxI = Math.floor(props.dtot[id] / kMeridianPos.resolution);
        for(let i = 0; i <= maxI; i++)
        {
            const dia = i * kMeridianPos.resolution;

            const kSagNeg = kMeridianNeg.sag(dia);
            const clSagNeg = clMeridianNeg.sag(dia);
            out[id].unshift({
                x: -dia,
                y: Math.round((kSagNeg - clSagNeg + apex.value[id] + props.minTear) * 1000),
            });

            const kSagPos = kMeridianPos.sag(dia);
            const clSagPos = clMeridianPos.sag(dia);
            out[id].push({
                x: dia,
                y: Math.round((kSagPos - clSagPos + apex.value[id] + props.minTear) * 1000),
            });
        }
    }

    return out;
}

/**
 * Add an opacity value to an RGB color.
 *
 * @param {string} rgb   The color represented as `"rgb(255, 255, 255)"`.
 * @param {number} alpha The alpha (or opacity) value, between `0.00` and `1.00`.
 */
function rgbToRgba(rgb, alpha)
{
    if(!rgbRegex.test(rgb) && !rgbaRegex.test(rgb))
    {
        throw new Error(`Invalid format for rgb: "${rgb}"`);
    }

    // Limit alpha to a value between 0 and 1, with two decimals
    alpha = Math.round(Math.min(Math.max(0, alpha), 1) * 100) / 100;

    return rgb.replace(rgbRegex, `rgba($1, $2, $3, ${alpha})`);
}

/**
 * Parse an RGB color definition into its integer components.
 *
 * @param {string} rgb
 * @returns {{r: number, g: number, b: number}}
 */
function parseRgb(rgb)
{
    if(!rgbRegex.test(rgb))
    {
        throw new Error(`Invalid format for rgb: "${rgb}"`);
    }

    const [, r, g, b] = rgb.match(rgbRegex);
    return {
        r: parseInt(r),
        g: parseInt(g),
        b: parseInt(b),
    };
}

function updateHorizontalScale()
{
    graph.options.scales.x = props.horizontalScale;
    graph.update();
}


// ------------------------------------------------------------ LIFECYCLE HOOKS

onMounted(() =>
{
    initGraph(graphArea.value);
    setApex();
    fittingColors.value = getAllFittingColors();
});

onUpdated(() =>
{
    if(Object.keys(props.horizontalScale).length)
    {
        updateHorizontalScale();
    }
});

onUnmounted(() =>
{
    destroyGraph();
});
</script>

<style lang="scss" scoped>
.graph-area
{
    @apply cursor-crosshair;
}
</style>
