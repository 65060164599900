<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div
        class="sl-order-form__line-column"
        :class="columnClasses"
        @click.stop="$refs.input.focus()"
    >
        <!-- Label -->
        <div class="sl-order-form__label-wrapper">
            <span class="sl-order-form__label">
                {{ parameter.form_label }}
            </span>

            <span v-if="parameter.unit" class="sl-order-form__unit">
                [&nbsp;{{ parameter.unit }}&nbsp;]
            </span>
        </div>

        <!-- Field -->
        <div class="sl-order-form__field-wrapper">
            <select
                ref="input"
                v-model="tempValue"
                class="sl-order-form__select"
                :name="name"
                :tabindex="tabIndex"
                @focus="onFocus"
                @blur="onBlur"
            >
                <option
                    v-for="item in parameter.list_items"
                    :key="item.ovalue"
                    :value="item.ovalue"
                    :disabled="!item.is_enabled"
                >
                    {{ item.label }}

                    <template v-if="item.help">
                        ({{ item.help }})
                    </template>
                </option>
            </select>
        </div>

        <!-- Error -->
        <div v-if="error" class="mb-1 px-2 text-red-600 text-xs text-center">
            {{ error }}
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractInlineField from './AbstractInlineField.vue';

export default
{
    name: 'InlineSelectorField',

    extends: AbstractInlineField,

    emits: ['update:modelValue'],

    data()
    {
        return {
            isTouched: false,
            isFocused: false,
        };
    },

    computed:
    {
        tempValue:
        {
            get()
            {
                return this.modelValue;
            },

            set(value)
            {
                this.$emit('update:modelValue', value);
            },
        },
    },

    methods:
    {
        onFocus()
        {
            this.isFocused = true;
        },

        onBlur()
        {
            this.isFocused = false;
            this.isTouched = true;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__line-column .sl-order-form__field-wrapper
{
    @apply mx-0;
}

.sl-order-form__select
{
    @apply border-none px-2 w-full bg-transparent text-left;

    // Highlight focused input
    &:focus
    {
        @apply outline-none;
    }
}
</style>
