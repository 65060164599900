export default class ValidateReturnEntitiesConsistency
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const ret = this.v.leftReturn ?? this.v.rightReturn;

        // If return entity was different from current one => show
        // entities selector modal OR going straight if "force_current_entity" was given as URL query param.
        if(ret.order.order_by != this.v.cEntity.id)
        {
            console.log(`Return entity is different from current one => show entities selector modal`);
            this.v.showChooseEntityModal = true;

            return false;
        }

        return true;
    }
}
