<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <!-- Return Date -->
    <lsn-table-cell class="sl-return-cell__date">
        {{ rdate }}
    </lsn-table-cell>

    <!-- Return Reference / Lens Serial Number -->
    <lsn-table-cell class="sl-return-cell__reference">
        {{ oReturn.reference }}
        <div v-if="sn" class="sl-return-cell__sn">
            SN: {{ sn }}
        </div>
    </lsn-table-cell>

    <!-- Article -->
    <lsn-table-cell class="sl-return-cell__article">
        <div class="sl-return-cell__article-wrapper">
            <!-- Article Label -->
            {{ getLabel(order) }}

            <div class="sl-return-subcell__article-label">
                <!-- External Reference (if any) -->
                {{ order.extra?.ext_ref }}
            </div>
        </div>
    </lsn-table-cell>

    <!-- Return reason -->
    <lsn-table-cell class="sl-return-cell__return-reason">
        {{ oReturn.return_reason_label }}
    </lsn-table-cell>

    <!-- Credit Amount -->
    <lsn-table-cell class="sl-return-cell__credit">
        <span>{{ oReturn.currency_code }}</span>
        <span>{{ formattedCredit }}</span>
    </lsn-table-cell>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import moment from 'moment';
import { getLabel } from '@/shared/OrderTableSharedObjects';
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';

export default
{
    name: 'ReturnLineSummary',

    components:
    {
        LsnTableCell,
    },

    props:
    {
        oReturn:
        {
            type:     Object,
            required: true,
        },
    },

    computed:
    {
        order()
        {
            return this.oReturn.order;
        },

        rdate()
        {
            return moment(this.oReturn.rdate).format('DD.MM.YYYY');
        },

        sn()
        {
            return this.order.serial_number;
        },

        formattedCredit()
        {
            if($store.getters['account/can']('price:view'))
            {
                let price = (this.oReturn.special_price !== null) ? this.oReturn.special_price : this.oReturn.price;

                return this.$f(price, 'decimal:2');
            }
            else
            {
                return '-';
            }
        },
    },

    methods:
    {
        getLabel,
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.sl-return-cell__date
{
    @apply text-gray-400 text-xs;
}

.sl-return-cell__reference
{
    @apply text-gray-400 text-sm text-center;
}

.sl-return-cell__sn
{
    @apply mt-1 text-gray-500 text-xs;
}

.sl-return-subcell__ext-ref
{
    @apply ml-2 text-sm text-gray-500;
}

.sl-return-cell__article-wrapper
{
    width: calc(100% - 28px); // SlLensSide is 28px wide
    @apply flex flex-wrap grow;
}

.sl-return-subcell__article-label
{
    @apply w-full text-xs text-gray-400 whitespace-nowrap text-ellipsis;
}

.sl-return-cell__credit
{
    @apply flex justify-between text-gray-400 text-sm;
}

</style>
