<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="sl-form-dual__line sl-form-dual__line--unpadded sl-form-dual__line-split">

        <!-- Left Field -->
        <component
            :is="getFieldComponent(leftField)"
            side="left"
            :index="index"
            :field="leftField"
            @update="update"
        />

        <!-- Label -->
        <div class="sl-form-dual__label-wrapper text-center">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <label v-if="field.getLabel() !== 'NO_LABEL'" class="sl-form-dual__label text-center leading-5" v-html="field.getLabel()"></label>

            <span v-if="field.getUnit()" class="sl-form-dual__unit">
                [&nbsp;{{ field.getUnit() }}&nbsp;]
            </span>
        </div>

        <!-- Right Field -->
        <component
            :is="getFieldComponent(rightField)"
            side="right"
            :index="index"
            :field="rightField"
            @update="update"
        />

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import { upperFirst, camelCase } from 'lodash-es';
import FormFieldDate from './form-fields/FormFieldDate';
import FormFieldInfo from './form-fields/FormFieldInfo';
import FormFieldLongText from './form-fields/FormFieldLongText';
import FormFieldNumber from './form-fields/FormFieldNumber';
import FormFieldRadioButton from './form-fields/FormFieldRadioButton';
import FormFieldNone from './form-fields/FormFieldNone';
import FormFieldSelector from './form-fields/FormFieldSelector';
import FormFieldText from './form-fields/FormFieldText';

// Custom fields
import ReturnCreditAmount from './form-fields/customs/FormFieldReturnCreditAmount';
import SpecialPrice from './form-fields/customs/FormFieldSpecialPrice';

// Used for custom parameter form display.
const CUSTOM = {
    ReturnCreditAmount,
    SpecialPrice,
};

export default
{
    name: 'FormLineSplit',

    props:
    {
        index:
        {
            type:     Number,
            required: true,
        },

        formLine:
        {
            type:     Object,
            required: true,
        },
    },

    emits: ['update'],

    data()
    {
        return {
            currentFormLine: this.formLine,
        };
    },

    computed:
    {
        leftField()
        {
            return this.currentFormLine.fields.left;
        },

        rightField()
        {
            return this.currentFormLine.fields.right;
        },

        field()
        {
            return this.leftField || this.rightField;
        },
    },

    watch:
    {
        formLine(formLine)
        {
            this.currentFormLine = formLine;
        },
    },

    methods:
    {
        getFieldComponent(field)
        {
            if(!field)
            {
                return FormFieldNone;
            }

            const display = field.getFormSplitDisplay() || field.getFormDisplay();

            switch(display)
            {
                case 'DATE_INPUT':
                    return FormFieldDate;

                case 'INFO':
                    return FormFieldInfo;

                case 'LONG_TEXT_INPUT':
                    return FormFieldLongText;

                case 'NUMBER_INPUT':
                    return FormFieldNumber;

                case 'RADIO_BUTTON':
                    return FormFieldRadioButton;

                case 'SELECTOR':
                    return FormFieldSelector;

                case 'TEXT_INPUT':
                    return FormFieldText;

                    // case 'OPEN_SELECTOR':
                    //     return FormFieldSelector;

                    // case 'CHECKBOX':
                    //     return FormFieldCheckbox;

                case 'CUSTOM':
                    const customField = upperFirst(camelCase(field.getCode()));
                    console.log('CUSTOM FIELD:', customField);
                    return CUSTOM[customField];

                    // case 'HIDDEN':
                    //     return NoneFormField;

                default:
                    console.log('PARAMETER', field);
                    throw new Error(`Lens parameter splitted form display "${display}" does not exist for parameter with code "${field.getCode()}" on the "${this.side}" side.`);
            }
        },

        update(paramCode, paramValue, side)
        {
            console.log('FormLineSplit', 'update', paramCode, paramValue, side);
            this.$emit('update', paramCode, paramValue, side);
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

</style>
