<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="sl-return-identifier-form">

        <!-- Order Identifier Input (SN/OEM/reference) -->
        <div class="flex items-center justify-center">

            <div class="mr-2 text-gray-500 text-sm">
                {{ $t('return.form.field.order_identifier.label') }}
            </div>

            <input
                :value="orderIdentifier"
                type="text"
                class="sl-return-identifier-form__input-field"
                :placeholder="$t('order.renewal_form.placeholder')"
                @input="onOrderIdentifierChange($event.target.value)"
                @keypress.enter="$emit('next')"
            >

            <div>
                <label class="flex items-center ml-6 text-gray-500 text-sm">
                    <lsn-switch
                        :model-value="returnBothLenses"
                        class="lsn-form__wrapper--padless mr-2"
                        @change="onReturnBothLensesChange($event.target.value)"
                    />
                    {{ $t('return.form.field.return_both_lens.label') }}
                </label>
            </div>

        </div>

        <!-- Error -->
        <div v-if="error" class="mt-4 w-full text-red-600 text-sm text-center">
            {{ error }}
        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

let searchSnDebounce;

export default
{
    name: 'OrderIdentifierForm',

    // ------------------------------------------------------------------------- PROPS

    props:
    {
        orderIdentifier: // Lens Serial Number or OEM Number or Order Reference
        {
            type:    [String, Number],
            default: null,
        },
        returnBothLenses:
        {
            type:    [Boolean],
            default: false,
        },
        error:
        {
            type:    String,
            default: null,
        },
    },

    // ------------------------------------------------------------------------- EMIT

    emits: ['update:returnBothLenses', 'update:orderIdentifier', 'next'],

    // ------------------------------------------------------------------------- METHODS

    methods:
    {
        onOrderIdentifierChange(orderIdentifier)
        {
            orderIdentifier = (orderIdentifier.length > 0) ? orderIdentifier : null; // Important !

            if(orderIdentifier)
            {
                orderIdentifier = orderIdentifier.replaceAll(/\s/g, '');
            }

            // Avoid sending ajax request on every inputs.
            clearTimeout(searchSnDebounce);

            searchSnDebounce = setTimeout(() =>
            {
                this.$emit('update:orderIdentifier', orderIdentifier);
            }, 400);
        },

        onReturnBothLensesChange()
        {
            this.$emit('update:returnBothLenses', !this.returnBothLenses);
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

.sl-return-identifier-form
{
    @apply relative py-4 m-4 bg-primary-10;
}

.sl-return-identifier-form__input-field
{
    @apply py-1 px-3 text-center border border-primary-200 outline-none rounded-full text-primary-500 tracking-wider;
}

.sl-return-identifier-form__input-field::placeholder
{
    @apply text-sm;
}

</style>
