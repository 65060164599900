<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-main">
        <div class="sl-page sl-page--full">
            <div class="sl-page__header">
                <div class="sl-page__title">
                    {{ $t('basket.title') }}
                </div>
            </div>

            <div v-if="lines.length" class="sl-page__content">
                <basket-table
                    v-model:selected-ids="selectedBasketLineIds"
                    :basket-lines="lines"
                    @remove="remove"
                    @edit="edit"
                />

                <!-- Total -->
                <div class="sl-total-line">
                    <!-- Text -->
                    <div class="sl-total-line__label">
                        {{ $t('common.total') }}
                    </div>

                    <!-- Amount & Currency -->
                    <div class="sl-total-line__amount" :class="{ 'blur': !pricesVisible }">
                        <span>{{ currency_code }}</span>
                        <span>{{ total }}</span>
                    </div>

                    <!-- Delete Button -->
                    <div class="sl-total-line__actions">
                        <div
                            v-tippy="{ arrow: true, animation: 'fade', placement: 'bottom' }"
                            class="sl-action-btn__wrapper"
                            content="Vider le panier"
                            @click.stop="emptyBasketConfirmationOpen = true"
                        >
                            <mdi-icon class="sl-action-btn" icon="mdiDelete" />
                        </div>
                    </div>

                    <!-- Tax Text -->
                    <div class="sl-total-line__text w-full">
                        ({{ $t('common.excluding-taxes') }})
                    </div>
                </div>

                <!-- Current Promotion -->
                <!-- <opti-munich-promotion
                    v-if="hasOptiTicket && !hasOptiTicketInBasket"
                    format="spread"
                    class="my-8"
                /> -->

                <!-- Basket Tools -->
                <div class="sl-basket-line-tools">
                    <!-- Switch "Consolidate orders into one shipment" -->
                    <div v-if="$store.getters['account/isDeveloper']" class="flex items-center">
                        <lsn-switch v-model="options.link_lines" class="lsn-form__wrapper--padless mr-3" />

                        <div
                            class="cursor-pointer text-sm text-gray-500"
                            @click="options.link_lines = !options.link_lines"
                        >
                            {{ $t('basket.link_orders') }}
                        </div>
                    </div>

                    <div class="grow"></div>

                    <!-- Switch "Send confirmation email" -->
                    <div v-is-staff class="flex items-center mr-8">
                        <div
                            class="cursor-pointer text-right text-sm text-gray-500"
                            @click="options.send_confirmation_email = !options.send_confirmation_email"
                        >
                            {{ $t('basket.send_confirmation_email') }}
                        </div>

                        <lsn-switch
                            v-model="options.send_confirmation_email"
                            class="lsn-form__wrapper--padless ml-3"
                        />
                    </div>

                    <button
                        class="flex justify-center items-center"
                        :class="placeOrderButtonClass"
                        @click="placeOrderButtonClicked"
                    >
                        <div>
                            {{ selectedBasketLineIds.length ? $t('basket.order_selected') : $t('basket.place_order') }}
                        </div>

                        <div
                            class="inline-flex justify-center items-center rounded-full h-5 bg-white/20 text-xs leading-none overflow-hidden"
                            :class="selectedBasketLineIds.length ? 'ml-2 w-5' : 'ml-0 w-0'"
                        >
                            {{ selectedBasketLineIds.length }}
                        </div>
                    </button>
                </div>

                <!-- Blocked Order Notice -->
                <div v-if="isBlocked" class="mt-8 p-2 w-full text-yellow-500 bg-yellow-100 border border-yellow-400 text-sm text-center">
                    {{ $t('basket.blocked_order.line1') }}
                    <br />
                    {{ $t('basket.blocked_order.line2') }}
                </div>

                <!-- Shipping Costs -->
                <div class="mt-6 w-full text-gray-400 text-sm leading-relaxed">
                    {{ shippingCosts }}
                </div>

                <!-- Promotions -->
                <div v-if="cEntity.country == 'FR'" class="w-1/2 mx-auto my-4">
                    <img src="/img/opto-meetings-2025.jpg" />
                </div>

                <!-- Related Articles -->
                <div v-if="showRelatedArticles" class="mt-8">
                    <div class="mb-4 text-lg text-primary-500">
                        {{ $t('basket.related_articles.title') }}
                    </div>

                    <related-articles />
                </div>
            </div>

            <!-- Empty View -->
            <div v-else class="sl-page__content flex flex-wrap mt-20">
                <div class="w-full flex justify-center">
                    <mdi-icon icon="mdiBasket" class="empty-basket-icon" />
                </div>

                <div class="w-full text-center text-gray-400 text-lg mt-8">
                    {{ $t('basket.empty_text') }}
                </div>

                <div class="w-full text-center text-primary-500 text-lg mt-8">
                    <router-link :to="{ name: 'order-lenses', params: $route.params }">
                        {{ $t('basket.make_an_order') }}
                    </router-link>
                </div>
            </div>

            <!-- Empty Basket Modal -->
            <teleport to="body">
                <lsn-modal :is-open="emptyBasketConfirmationOpen">
                    <div class="sl-prompt sl-prompt--danger">
                        <div class="sl-prompt__contents">
                            <div class="sl-prompt__title">
                                {{ $t('basket.empty_confirmation') }}
                            </div>

                            <div class="sl-prompt__text">
                                {{ $t('common.action_irreversible') }}
                            </div>
                        </div>

                        <div class="sl-prompt__actions">
                            <button class="sl-prompt__button sl-prompt__button--white" @click.stop="emptyBasketConfirmationOpen = false">
                                {{ $t('common.cancel') }}
                            </button>

                            <button class="sl-prompt__button sl-prompt__button--red" @click.stop="emptyBasket()">
                                {{ $t('common.remove') }}
                            </button>
                        </div>
                    </div>
                </lsn-modal>
            </teleport>

            <!-- Condition Refused Modal -->
            <teleport to="body">
                <lsn-modal :is-open="conditionRefusedOpen">
                    <div class="sl-prompt sl-prompt--info">
                        <div class="sl-prompt__contents">
                            <div class="sl-prompt__title">
                                {{ $t('basket.conditions_refused.title') }}
                            </div>

                            <div class="sl-prompt__text">
                                {{ conditionRefusedText }}
                            </div>
                        </div>

                        <div class="sl-prompt__actions">
                            <button class="sl-prompt__button sl-prompt__button--white" @click="conditionRefusedOpen = false">
                                {{ $t('common.close') }}
                            </button>
                        </div>
                    </div>
                </lsn-modal>
            </teleport>

            <!-- HydroColor Pro Cleaning Solution Warning/Condition -->
            <teleport to="body">
                <lsn-modal :is-open="hydroColorProCareWarningOpen">
                    <div class="sl-prompt sl-prompt--danger">
                        <div class="sl-prompt__contents">
                            <div class="sl-prompt__title">
                                {{ $t('prototypes.notice.care_instructions.title') }}
                            </div>

                            <div class="sl-prompt__text">
                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <div v-html="formatNotificationText($t('prototypes.notice.care_instructions.text'))"></div>

                                <div class="flex items-center mt-4 -mb-4">
                                    <lsn-switch v-model="hydroColorProCareWarningAccepted" class="lsn-form__wrapper--padless" />

                                    <div class="cursor-pointer ml-4 select-none" @click="hydroColorProCareWarningAccepted = !hydroColorProCareWarningAccepted">
                                        {{ $t('common.i_have_read_and_understood') }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="sl-prompt__actions">
                            <button class="sl-prompt__button sl-prompt__button--white" @click.stop="hydroColorProCareWarningAccepted = hydroColorProCareWarningOpen = false">
                                {{ $t('common.cancel') }}
                            </button>

                            <button class="sl-prompt__button" :class="hydroColorProCareWarningAccepted ? 'sl-prompt__button--primary' : 'sl-prompt__button--disabled'" @click.stop="placeHydroColorOrder">
                                {{ $t('basket.place_order') }}
                            </button>
                        </div>
                    </div>
                </lsn-modal>
            </teleport>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import constants           from '@/constants/constants';
import LensOrderUrlManager from '@/views/order/lenses/LensOrderUrlManager';
import BasketTable         from '@/components/basket/BasketTable.vue';
import RelatedArticles     from '@/components/basket/RelatedArticles.vue';
import OptiMunichPromotion from '@/components/promotions/OptiMunichPromotion.vue';
import useOptiMunichPromotionComposable from '@/composables/OptiMunichPromotionComposable';

const HCP_CARE_NOTIFICATION            = 'HYDROCOLOR_PRO_CARE_INSTRUCTIONS';
const HCP_INCOMPATIBILITY_NOTIFICATION = 'HYDROCOLOR_PRO_INCOMPATIBILITY_WARNING';

export default
{
    name: 'BasketPage',

    components:
    {
        BasketTable,
        OptiMunichPromotion,
        RelatedArticles,
    },

    setup()
    {

        const {
            hasOptiTicket,
            hasOptiTicketInBasket,
        } = useOptiMunichPromotionComposable();

        return {
            // Computed
            hasOptiTicket,
            hasOptiTicketInBasket,
        };
    },

    data()
    {
        return {
            selectedBasketLineIds:            [],
            emptyBasketConfirmationOpen:      false,
            hydroColorProCareWarningOpen:     false,
            hydroColorProCareWarningAccepted: false,
            conditionRefusedOpen:             false,
            conditionRefusedText:             null,
            addingToBasket:                   false,
            options:
            {
                link_lines:              false,
                send_confirmation_email: true,
            },
        };
    },

    computed:
    {
        lines()
        {
            return this.$store.state.basket.lines.map(bl => this.formatBasketLine(bl));
        },

        total()
        {
            const total = this.lines.reduce((carry, basketLine) =>
            {
                if(basketLine.special_price !== null)
                {
                    // Special price (if any)
                    return carry + basketLine.special_price;
                }
                else
                {
                    // Normal case
                    const supplementsPrice = (basketLine.supplements || []).reduce(
                        (carry, supplement) => carry + supplement.quantity * supplement.price,
                        0
                    );

                    return carry + basketLine.quantity * (basketLine.price + supplementsPrice);
                }
            }, 0);

            return this.$f(total, 'decimal:2|thousand');
        },

        currency_code()
        {
            return this.lines?.[0]?.currency_code || null;
        },

        isBlocked()
        {
            return this.cEntity.status === 'BLOCKED_ORDER';
        },

        isDemo()
        {
            return this.cEntity.type == 'DEMO';
        },

        shippingCosts()
        {
            const lensShippingCost = this.$store.getters['catalog/getByCode']('SL_SHIPPINGCOSTS_LENS');
            const acuacareShippingCost = this.$store.getters['catalog/getByCode']('SL_SHIPPINGCOSTS_ACUACARE');
            if(!lensShippingCost || !acuacareShippingCost)
            {
                return '';
            }

            const currency = lensShippingCost.currency_code;
            const acuacareFreeShippingThreshold = this.$aconfig.get(
                `SWISSLENS.free_shipping_threshold.${currency}`,
                ''
            );

            const text = this.$t('basket.notice.shipping_costs.text', {
                currency,
                SL_SHIPPINGCOSTS_LENS:                this.$f(lensShippingCost.price, 'decimal:2'),
                SL_SHIPPINGCOSTS_ACUACARE:            this.$f(acuacareShippingCost.price, 'decimal:2'),
                SL_SHIPPINGCOSTS_ACUACARE_FREE_LEVEL: acuacareFreeShippingThreshold,
            });

            return text;
        },

        placeOrderButtonClass()
        {
            const classes = ['px-8', 'py-3', 'transition'];

            if(this.isBlocked || this.isDemo)
            {
                classes.unshift('cursor-not-allowed');
                classes.push('bg-gray-300', 'text-gray-500');
            }
            else
            {
                classes.push('bg-primary-500', 'text-white');
                classes.push('hover:bg-primary-300', 'focus:bg-primary-300', 'active:bg-primary-600');
            }

            return classes;
        },

        showRelatedArticles()
        {
            const hasCleaningProducts = (this.$store.getters['catalog/getByTag']('CLEANING_PRODUCT').length > 0);
            const hasAccessories = (this.$store.getters['catalog/getByTag']('ACCESSORY').length > 0);

            return (hasCleaningProducts || hasAccessories) && !this.isBlocked;
        },

        hasHydroColorPro()
        {
            const requiredTags = ['HYDROCOLOR_PRO', 'LENS'];

            const lines = this.$store.state.basket.lines;
            for(const line of lines)
            {
                const tagCodes = this.$store.getters['catalog/getByCode'](line.article_code).tags.map(t => t.code);
                if(requiredTags.every(rt => tagCodes.includes(rt)))
                {
                    return true;
                }
            }

            return false;
        },

        hasHydroColorProIncompatibleCleaningSolution()
        {
            for(const line of this.$store.state.basket.lines)
            {
                const tags = this.$store.getters['catalog/getByCode'](line.article_code).tags.map(t => t.code);
                if(tags.includes('CLEANING_PRODUCT') && !tags.includes('PROSTHETIC_COMPATIBLE'))
                {
                    // There is at least one line containing a cleaning solution
                    // which is not prosthetic-compatible.
                    return true;
                }
            }

            return false;
        },

        pricesVisible()
        {
            return this.$store.state.pricesVisible;
        },

        cEntity()
        {
            return this.$store.state.account.cEntity;
        },
    },

    mounted()
    {
        this.updateHydroColorProCareNotification();
        this.updateHydroColorProIncompatibilityNotification();
    },

    updated()
    {
        this.updateHydroColorProCareNotification();
        this.updateHydroColorProIncompatibilityNotification();
    },

    unmounted()
    {
        this.removeHydroColorProCareNotification();
        this.removeHydroColorProIncompatibilityNotification();
    },

    methods:
    {
        formatBasketLine(basketLine = {})
        {
            const article = this.getArticleOrThrow(basketLine.article_code);

            // Format the basket line for use in the basket table component
            const formattedBasketLine = {
                id:              basketLine.id                                               || null,
                quantity:        basketLine.quantity                                         || null,
                article_data:    basketLine.article_data                                     || [],
                logistics_data:  basketLine.logistics_data                                   || [],
                return_data:     basketLine.return_data                                      || [],
                dependency_code: basketLine.dependency_code                                  || null,
                price:           parseFloat(article.price),
                currency_code:   article.currency_code                                       || null,
                order_by:        basketLine.order_by                                         || null,
                delivery_to:     basketLine.delivery_to                                      || null,
                user_id:         basketLine.added_by_user_id || basketLine.placed_by_user_id || null,
                extra:           basketLine.extra                                            || {},
                article:
                {
                    status: article.status,
                    code:   article.code,
                    label:  article.label,
                    price:  article.price,
                    tags:   article.tags,
                    extra:  article.extra,
                },
                supplements:               [], // filled later in this method
                previous_order_reference:  basketLine.previous_order_reference                || null,
                special_price:             parseFloat(basketLine.special_price),
                special_price_is_computed: !!basketLine.special_price_is_computed,
                icom:                      basketLine.icom                                    || null,
                created_at:                basketLine.created_at                              || null,
                updated_at:                basketLine.updated_at                              || null,
            };

            // Validate ID
            if(!formattedBasketLine.id)
            {
                throw new RangeError(`Invalid ID "${basketLine.id}" for a basket line`);
            }

            // Validate price
            if(isNaN(formattedBasketLine.price))
            {
                throw new Error(`Invalid price for ${article.code}: "${article.price}" => NaN`);
            }

            // Validate special price: can be null, 0 or a greater value!
            if(isNaN(formattedBasketLine.special_price))
            {
                formattedBasketLine.special_price = null;
            }

            // Fill supplements, complemented with data from the store
            (basketLine.supplements || []).forEach(({ article_code: articleCode, quantity }) =>
            {
                const supplement = this.getArticleOrThrow(articleCode);
                formattedBasketLine.supplements.push(Object.assign({}, supplement, { quantity }));
            });

            return formattedBasketLine;
        },

        getArticleOrThrow(articleCode)
        {
            const article = this.$store.getters['catalog/getByCode'](articleCode);

            if(!article)
            {
                const clientNumber = this.cEntity.ext_id;

                throw new Error(`Article "${articleCode}" not in catalog for entity "${clientNumber}"`);
            }

            return article;
        },

        edit(basketLine1)
        {
            let basketLine2;
            let side1;
            let side2;

            let lensOrderUrlManager = new LensOrderUrlManager(this.$route);

            // If current line is linked to another line.
            if(basketLine1.article_data.LENS_PAIR_ID)
            {
                basketLine2 = this.$store.getters['basket/getLineByPairId'](basketLine1.article_data.LENS_PAIR_ID);
            }

            if(!basketLine1.article_data?.LENS_SIDE)
            {
                throw new Error(`Basket line with id "${basketLine1.id}" has no "LENS_SIDE" parameter, so it cannot be edited.`);
            }

            side1 = basketLine1.article_data.LENS_SIDE;

            // Manage "single" side case.
            if(side1 == 'single')
            {
                // If lens pair ID exists => check if it contains letter "A" => "A" is right, "B" is left. (cf handler: SetLensPairId.js)
                if(basketLine1.article_data.LENS_PAIR_ID)
                {
                    side1 = basketLine1.article_data.LENS_PAIR_ID.includes('A') ? 'right' : 'left';
                }
                else
                {
                    side1 = 'right';
                }
            }

            lensOrderUrlManager.setBasketLineId(side1, basketLine1.id);

            // If other has been retreived.
            if(basketLine2)
            {
                if(!basketLine2.article_data?.LENS_SIDE)
                {
                    throw new Error(`Basket line with id "${basketLine2.id}" has no "LENS_SIDE" parameter, so it cannot be edited.`);
                }

                side2 = (side1 == 'right') ? 'left' : 'right';

                lensOrderUrlManager.setBasketLineId(side2, basketLine2.id);
            }

            this.$router.push({ path: lensOrderUrlManager.getPath() });
        },

        remove(order)
        {
            this.$store.dispatch('basket/deleteLine', order.id);
        },

        placeOrderButtonClicked()
        {
            if(this.isBlocked || this.isDemo)
            {
                return;
            }

            const msg = this.checkConditions();
            if(msg !== true)
            {
                this.conditionRefusedOpen = true;
            }

            // Display the warning modal forcing the user to acknowledge
            // the risk of using the wrong cleaning solution with their
            // HydroColor Pro lenses.
            if(this.hasHydroColorPro)
            {
                this.hydroColorProCareWarningOpen = true;

                return; // place() may be called via placeHydroColorOrder()
            }

            // If no precondition failed, place the order!
            this.place();
        },

        checkConditions()
        {
            return true;
        },

        placeHydroColorOrder()
        {
            if(!this.hydroColorProCareWarningAccepted)
            {
                return;
            }

            this.place();
        },

        place()
        {
            this.$store.dispatch('basket/place', {
                options:               this.options,
                selectedBasketLineIds: this.selectedBasketLineIds,
            })
                .then(() =>
                {
                    this.$router.push({
                        name:   'order-confirmation',
                        params: this.$route.params,
                    });
                })
                .catch(error =>
                {
                    // TODO: Handle error
                });
        },

        emptyBasket()
        {
            this.$store.dispatch('basket/deleteAllLines')
                .then(() =>
                {
                    this.emptyBasketConfirmationOpen = false;
                });
        },

        /**
         * Manage the notification pertaining to HydroColor Pro care instructions.
         */
        updateHydroColorProCareNotification()
        {
            if(this.hasHydroColorPro)
            {
                // Add the notification (if not already there)
                if(!this.$store.getters['notifications/hasBandNotification'](HCP_CARE_NOTIFICATION))
                {
                    this.$store.dispatch('notifications/addBandNotification', {
                        id:          HCP_CARE_NOTIFICATION,
                        dismissible: false,
                        theme:       'warn',
                        title:       'prototypes.notice.care_instructions.title',
                        text:        'prototypes.notice.care_instructions.text',
                    });
                }
            }
            else
            {
                // Remove the notification, if any
                this.removeHydroColorProCareNotification();
            }
        },

        removeHydroColorProCareNotification()
        {
            this.$store.dispatch('notifications/removeBandNotification', HCP_CARE_NOTIFICATION);
        },

        /**
         * Manage the warning about incompatible HydroColor Pro cleaning solutions being in the basket.
         */
        updateHydroColorProIncompatibilityNotification()
        {
            if(this.hasHydroColorPro && this.hasHydroColorProIncompatibleCleaningSolution)
            {
                // Add the notification (if not already there)
                if(!this.$store.getters['notifications/hasBandNotification'](HCP_INCOMPATIBILITY_NOTIFICATION))
                {
                    this.$store.dispatch('notifications/addBandNotification', {
                        id:          HCP_INCOMPATIBILITY_NOTIFICATION,
                        dismissible: false,
                        theme:       'danger',
                        title:       'prototypes.notice.prosthetic_incompatible_cleaning_solution.title',
                        text:        'prototypes.notice.prosthetic_incompatible_cleaning_solution.text',
                    });
                }
            }
            else
            {
                // Remove the notification, if any
                this.removeHydroColorProIncompatibilityNotification();
            }
        },

        removeHydroColorProIncompatibilityNotification()
        {
            this.$store.dispatch('notifications/removeBandNotification', HCP_INCOMPATIBILITY_NOTIFICATION);
        },

        // todo: Refactor this method; same code as AppLayout.vue
        formatNotificationText(text)
        {
            return text.replace(/(\r\n|\r|\n)/g, '<br>') // new lines
                .replace( // urls
                    /((?:https?:\/{2})(?:[a-z0-9]{2,}\.?){2,}(?:\/[a-z0-9\-]*)*)/gmi,
                    '<a href="$1" target="_blank" class="sl-notification__link">$1</a>'
                );
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
$actions-col-width: 104px;

.sl-total-line
{
    @apply flex flex-wrap items-center mt-1.5;
}

.sl-total-line__label
{
    @apply grow mr-4 text-gray-600 text-right;
}

.sl-total-line__amount
{
    width: 110px;
    @apply flex justify-between items-center px-2 text-primary-500;
}

.sl-total-line__actions
{
    width: $actions-col-width;
    @apply flex items-center justify-end pr-2;
}

.sl-total-line__text
{
    margin-right: $actions-col-width;
    @apply w-full text-xs text-right text-gray-500;
}

.sl-basket-line-tools
{
    @apply flex justify-between items-center mt-4 w-full;

    padding-right: $actions-col-width;
}

.empty-basket-icon
{
    @apply w-[100px] h-[100px] text-gray-300;
}

.sl-action-btn__wrapper
{
    @apply cursor-pointer flex justify-center items-center border border-primary-100 w-[34px] h-[34px] text-primary-500;
}

.sl-action-btn__wrapper:hover
{
    @apply bg-primary-200;
}

.sl-action-btn__wrapper .mdi-icon
{
    @apply w-5 h-5;
}
</style>
