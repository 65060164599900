import moment from "moment";
import FormField from "./FormField";

export default class FormFieldDate extends FormField
{
    setValue(value)
    {
        const format = this.getDateFormat();

        if(format)
        {
            console.log('Before date formatting', format, value);
            if(moment(value, format).isValid())
            {
                value = moment(value, format).format('YYYY-MM-DD');
            }
            console.log('After date formatting', format, value);
        }

        this.parameter.setValue(value);
    }

    getDateFormat()
    {
        if(typeof this.parameter.formatters === 'string')
        {
            const regex = /datef:(.*)/;
            const match = this.parameter.formatters.match(regex);

            if(match)
            {
                return match[1];
            }
        }

        return null;
    }
}
