// ==================
// Lens Return Module
// ==================

import axios from 'axios';
import { isEmpty } from 'lodash-es';

export function setupLensReturnModule(i18n, VueHoneybadger)
{
    return {

        namespaced: true,

        // --------------------------------------------------------------------- STATE

        state()
        {
            return {
                formDual: null,
                returns:
                {
                    left:  null,
                    right: null,
                },
                lastReturns: [],
            };
        },

        // --------------------------------------------------------------------- GETTERS

        getters:
        {
            getReturn: state => side =>
            {
                return state.returns[side];
            },
        },

        // --------------------------------------------------------------------- MUTATIONS

        mutations:
        {
            setFormDual(state, formDual)
            {
                state.formDual = formDual;
            },

            setReturn(state, { ret, side })
            {
                state.returns[side] = ret;
            },

            setLastReturns(state, returns)
            {
                state.lastReturns = returns;
            },

            reset(state)
            {
                state.formDual = null;
                state.returns =
                {
                    left:  null,
                    right: null,
                };
            },
        },

        // --------------------------------------------------------------------- ACTIONS

        actions:
        {
            template({ state, rootState }, { side, isInitialTemplate = false })
            {
                return state.returns[side].template(isInitialTemplate);
            },

            price({ state, rootState }, { side })
            {
                let apiReturn = state.returns[side].toApi();

                apiReturn.return_questionnaire_answers = state.returnQuestionnaires[side].toApi();

                let url = '';

                const data = {
                    returns: [apiReturn],
                    options: {},
                };

                return axios.post(url, data);
            },
        },
    };
};
