<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="sl-main">

        <div v-if="!initializing" class="sl-page sl-page--full sl-page--paddless">

            <div class="m-4 text-xl text-primary-500">
                {{ $t('return.title') }}
            </div>

            <!-- Step 1 : select lens order to retrieve -->
            <template v-if="(!leftReturn && !rightReturn) || orderIdentifierFormError">

                <!-- order identifier form -->
                <order-identifier-form
                    v-model:order-identifier="orderIdentifier"
                    v-model:return-both-lenses="returnBothLenses"
                    :error="orderIdentifierFormError"
                    @next="dispatch()"
                />

                <!-- "Next" Button -->
                <div class="flex w-full items-center justify-center">
                    <button class="lsn-btn" :class="nextButtonClass" @click="dispatch()">
                        {{ $t('common.next') }}
                    </button>
                </div>

            </template>

            <template v-else-if="formDual">

                <form-dual
                    :form-dual="formDual"
                    @update="template"
                />

                <div v-if="formDual.hasErrors()" class="flex mx-3 my-4 p-3 bg-red-100">
                    <div class="w-1/2 text-red-600 text-center text-sm">
                        {{ formDual.getError('left') }}
                    </div>
                    <div class="w-1/2 text-red-600 text-center text-sm">
                        {{ formDual.getError('right') }}
                    </div>
                </div>

                <!-- "Submit" Button -->
                <div class="my-6">
                    <div class="flex justify-center w-full">
                        <button class="lsn-btn lsn-btn--primary sl-order__submit" :tabindex="3" @click="save">
                            {{ $t('return.lenses.save-btn') }}
                        </button>
                    </div>
                </div>

            </template>
        </div>

        <!-- Loader (while initializing or loading) -->
        <div v-if="initializing || loading" class="mt-8 mx-auto text-center">
            <img class="inline" src="/img/loader-5.gif">
        </div>

        <!-- Re-order: not same entity modal warning -->
        <choose-entity-modal
            :is-open="showChooseEntityModal"
            :ret="leftReturn ?? rightReturn"
            @close="closeEntitySwitchAndReset"
            @next="dispatch"
        />

        <!-- Dispatch Error -->
        <teleport to="body">
            <lsn-modal :is-open="dispatchError !== null">
                <div class="sl-prompt sl-prompt--danger text-center">
                    <div class="sl-prompt__contents">
                        <div class="sl-prompt__title">
                            {{ dispatchErrorTitle }}
                        </div>

                        <div class="sl-prompt__text">
                            {{ dispatchErrorText }}
                        </div>
                    </div>

                    <div class="sl-prompt__actions">
                        <button class="sl-prompt__button sl-prompt__button--white" @click="dispatchError = null">
                            {{ $t('common.close') }}
                        </button>
                    </div>
                </div>
            </lsn-modal>
        </teleport>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import {
    get,
} from 'lodash-es';

// Helper Objects
import LensReturnUrlManager from './LensReturnUrlManager';

// Components
import OrderIdentifierForm from './OrderIdentifierForm';
import FormDual from '@/components/forms/form-dual/FormDual';
import ChooseEntityModal from './ChooseEntityModal';

// Chain of responsibility handlers: "dispatch"
import ResetErrors from './handlers/ResetErrors';
import ValidateLensOrderIdentifier from './handlers/ValidateLensOrderIdentifier';
import SilentlySetUrlManagerParameter from './handlers/SilentlySetUrlManagerParameter';
import FetchOrderByReference from './handlers/FetchOrderByReference';
import FetchOrderByLensSn from './handlers/FetchOrderByLensSn';
import FetchOrderByLensOemNumber from './handlers/FetchOrderByLensOemNumber';
import CheckReturnOrStop from './handlers/CheckReturnOrStop';
import FetchPairedOrderByReference from './handlers/FetchPairedOrderByReference';
import CheckAlreadyShipped from './handlers/CheckAlreadyShipped';
import CheckNotAlreadyReturned from './handlers/CheckNotAlreadyReturned';
import ValidateReturnEntitiesConsistency from './handlers/ValidateReturnEntitiesConsistency';
import TemplateReturnQuestionnaire from './handlers/TemplateReturnQuestionnaire';
import SetReturnQuestionnaireValues from './handlers/SetReturnQuestionnaireValues';
import CreateFormDual from './handlers/CreateFormDual';
import LinkReturnQuestionnairesToFormDual from './handlers/LinkReturnQuestionnairesToFormDual';
import RenderFormDual from './handlers/RenderFormDual';

// Chain of responsibility handlers: "save"
import ValidateReturns from './handlers/ValidateReturns';
import PriceReturns from './handlers/PriceReturns';
import CreateReturns from './handlers/CreateReturns';
import NavigateToReturnConfirmation from './handlers/NavigateToReturnConfirmation';

// Chain of responsibility maps (used as queues)
const dispatchQueue = new Map();
const createReturnQueue = new Map();

export default
{
    name: 'ReturnLenses',

    // ------------------------------------------------------------------------- COMPONENTS

    components:
    {
        OrderIdentifierForm,
        FormDual,
        ChooseEntityModal,
    },

    // ------------------------------------------------------------------------- DATA

    data()
    {
        return {
            // Status
            initializing: false,
            loading:      false,

            // URL
            lensReturnUrlManager:  null,
            dispatchOnRouteChange: true,

            // Other
            showChooseEntityModal: false,

            // Lenses
            orderIdentifier:  null,
            returnBothLenses: true,

            // Errors
            orderIdentifierFormError: null,
            dispatchError:            null,
        };
    },

    // ------------------------------------------------------------------------- COMPUTED PROPERTIES

    computed:
    {
        /**
         * Used in handlers!
         */
        cEntity()
        {
            return this.$store.state.account.cEntity;
        },

        isNextButtonActive()
        {
            return this.orderIdentifier;
        },

        nextButtonClass()
        {
            return this.isNextButtonActive
                ? 'lsn-btn--primary sl-order__submit'
                : 'lsn-btn--gray-outline lsn-btn--disabled';
        },

        leftReturn:
        {
            get()
            {
                return this.$store.state.lensReturn.returns.left;
            },
            set(leftReturn)
            {
                this.$store.commit('lensReturn/setReturn', {
                    ret:  leftReturn,
                    side: 'left',
                });
            },
        },

        rightReturn:
        {
            get()
            {
                return this.$store.state.lensReturn.returns.right;
            },
            set(rightReturn)
            {
                this.$store.commit('lensReturn/setReturn', {
                    ret:  rightReturn,
                    side: 'right',
                });
            },
        },

        formDual()
        {
            return this.$store.state.lensReturn.formDual;
        },
    },

    // ------------------------------------------------------------------------- WATCHERS

    watch:
    {
        'leftReturn.return_reason_code': function(reasonCode)
        {
            this.formDual.resetError('left');

            if(!reasonCode)
            {
                this.leftReturn.resetPrice();
            }
            else
            {
                this.leftReturn.fetchPrice().catch(error =>
                {
                    if(error.response.status == 422)
                    {
                        this.formDual.setErrors(error.response.errors[0], 'left');
                        console.log('FORM DUAL ERRORS (left)', this.formDual.hasErrors(), this.formDual.getError('left'), this.formDual.getError('left'));
                    }
                });
            }
        },

        'rightReturn.return_reason_code': function(reasonCode)
        {
            this.formDual.resetError('right');

            if(!reasonCode)
            {
                this.rightReturn.resetPrice();
            }
            else
            {
                this.rightReturn.fetchPrice().catch(error =>
                {
                    if(error.response.status == 422)
                    {
                        this.formDual.setErrors(error.response.errors[0], 'right');
                        console.log('FORM DUAL ERRORS (right)', this.formDual.hasErrors(), this.formDual.getError('right'), this.formDual.getError('left'));
                    }
                });
            }
        },

        '$route.params'(newParams, oldParams)
        {
            // // Avoid retemplating when adding to basket
            // if(!['return-lenses', 'edit-return-lenses'].includes(this.$route.name))
            // {
            //     console.log('route has changed: dispatch avoided.');
            //     return;
            // }

            // this.lensReturnUrlManager.setFromRoute(this.$route);

            // // Exception when user clicks on "New Return" menu
            // if(!newParams.lenses)
            // {
            //     this.orderIdentifier = null;
            //     this.returnBothLenses = false;

            //     // Reset both sides
            //     this.$store.commit('lensReturn/reset');
            // }
            // else if(this.dispatchOnRouteChange)
            // {
            //     console.log('reacting to route changes!', oldParams, newParams);

            //     this.syncDataFromUrl();
            //     this.dispatch();
            // }
            // else
            // {
            //     console.log('not reacting to route changes!');
            // }
        },
    },

    // ------------------------------------------------------------------------- LIFECYCLE

    created()
    {
        // Build chains
        this.setupDispatchQueue();
        this.setupCreateReturnQueue();

        // Set data from URL
        this.lensReturnUrlManager = new LensReturnUrlManager(this.$route);
        this.syncDataFromUrl();

        if(this.orderIdentifier != null)
        {
            this.initializing = true;
            this.dispatch();
        }
        else
        {
            console.log('No lens order identifier given at creation');
        }
    },

    unmounted()
    {
        delete this.$route.params.lenses; // IMPORTANT
        this.$store.commit('lensReturn/reset'); // reset both sides
    },

    // ------------------------------------------------------------------------- METHODS

    methods:
    {
        setReturn(ret)
        {
            // Set return on the correct side (left or right).
            let side = get(ret.order, 'article_data.LENS_SIDE', 'right');

            // Trick for "single" side lenses.
            side = (['left', 'right'].indexOf(side) == -1) ? 'right' : side;

            if(side == 'left' && !this.rightReturn)
            {
                this.rightReturn = ret;
            }
            else if(side == 'left' && this.rightReturn)
            {
                this.leftReturn = this.rightReturn;
                this.rightReturn = ret;
            }
            else if(side == 'right' && !this.leftReturn)
            {
                this.leftReturn = ret;
            }
            else if(side == 'right' && this.leftReturn)
            {
                this.rightReturn = this.leftReturn;
                this.leftReturn = ret;
            }
        },

        closeEntitySwitchAndReset()
        {
            this.leftReturn = null;
            this.rightReturn = null;
            this.showChooseEntityModal = false;
        },

        // --------------------------------------------------------------------- chain : dispatch

        async dispatch(from = null)
        {
            console.log(`DISPATCH START! (from: ${from})`);

            let firstHandlerFound = false;

            // If no "from" has been given.
            if(!from)
            {
                // Set first key as "from".
                from = dispatchQueue.keys().next().value;
            }

            this.loading = true;

            for(const [key, handler] of dispatchQueue.entries())
            {
                if(key.includes(from))
                {
                    firstHandlerFound = true;
                }

                if(firstHandlerFound)
                {
                    console.groupCollapsed('Handler: ' + key);

                    try
                    {
                        const result = await handler.handle();
                        if(result === false)
                        {
                            console.groupEnd();
                            console.log('DISPATCH BREAK!');
                            break;
                        }
                    }
                    catch(error)
                    {
                        console.groupEnd();
                        console.log('DISPATCH BREAK!', error);
                        this.dispatchError = error;
                        break;
                    }

                    console.groupEnd();
                }
            }

            this.loading = false;
            this.initializing = false;

            console.log('DISPATCH END!');
        },

        template(paramCode, paramValue, side)
        {
            console.log('LensReturn', 'update', paramCode, paramValue, side);

            if(side == 'both')
            {
                this.templateBothSides();
            }
            else
            {
                this.templateOneSide(side);
            }
        },

        templateBothSides()
        {
            let leftReturn = this.$store.getters['lensReturn/getReturn']('left');
            let rightReturn = this.$store.getters['lensReturn/getReturn']('right');

            Promise.all([
                leftReturn.template(),
                rightReturn.template(),
            ]).then(() =>
            {
                this.formDual.setParameters('left', leftReturn.getReturnQuestionnaire().getParameters());
                this.formDual.setParameters('right', rightReturn.getReturnQuestionnaire().getParameters());
                this.formDual.render();
            });
        },

        templateOneSide(side)
        {
            const cReturn = this.$store.getters['lensReturn/getReturn'](side);

            cReturn.template().then(() =>
            {
                this.formDual.setParameters(side, cReturn.getReturnQuestionnaire().getParameters());
                this.formDual.render();
            });
        },

        // --------------------------------------------------------------------- Chain : save

        async save()
        {
            console.log('SAVE START!');

            this.$store.commit('overlay/setVisibility', true);

            for(const [key, handler] of createReturnQueue.entries())
            {
                console.groupCollapsed('Handler: ' + key);

                try
                {
                    const result = await handler.handle();

                    if(result === false)
                    {
                        console.groupEnd();
                        console.log('SAVE BREAK!');
                        break;
                    }
                }
                catch(error)
                {
                    console.groupEnd();
                    console.log('SAVE BREAK!', error);
                    break;
                }

                console.groupEnd();
            }

            this.$store.commit('overlay/setVisibility', false);

            console.log('SAVE END!');
        },

        // --------------------------------------------------------------------- Helpers

        syncDataFromUrl()
        {
            this.orderIdentifier = this.lensReturnUrlManager.getOrderIdentifier();
            this.returnBothLenses = this.lensReturnUrlManager.getReturnBothLenses();
        },

        // --------------------------------------------------------------------- Setup chains

        setupDispatchQueue()
        {
            dispatchQueue.set('ResetErrors',                        new ResetErrors(this));
            dispatchQueue.set('ValidateLensOrderIdentifier',        new ValidateLensOrderIdentifier(this));
            dispatchQueue.set('SilentlySetUrlManagerParameter',     new SilentlySetUrlManagerParameter(this));
            dispatchQueue.set('FetchOrderByReference',              new FetchOrderByReference(this));
            dispatchQueue.set('FetchOrderByLensSn',                 new FetchOrderByLensSn(this));
            dispatchQueue.set('FetchOrderByLensOemNumber',          new FetchOrderByLensOemNumber(this));
            dispatchQueue.set('CheckReturnOrStop',                  new CheckReturnOrStop(this));
            dispatchQueue.set('ValidateReturnEntitiesConsistency',  new ValidateReturnEntitiesConsistency(this));
            dispatchQueue.set('FetchPairedOrderByReference',        new FetchPairedOrderByReference(this));
            dispatchQueue.set('CheckNotAlreadyReturned',            new CheckNotAlreadyReturned(this));
            dispatchQueue.set('CheckAlreadyShipped',                new CheckAlreadyShipped(this));
            dispatchQueue.set('TemplateReturnQuestionnaire-left',   new TemplateReturnQuestionnaire(this, 'left'));
            dispatchQueue.set('TemplateReturnQuestionnaire-right',  new TemplateReturnQuestionnaire(this, 'right'));
            dispatchQueue.set('SetReturnQuestionnaireValues-left',  new SetReturnQuestionnaireValues(this, 'left'));
            dispatchQueue.set('SetReturnQuestionnaireValues-right', new SetReturnQuestionnaireValues(this, 'right'));
            dispatchQueue.set('CreateFormDual',                     new CreateFormDual(this));
            dispatchQueue.set('LinkReturnQuestionnairesToFormDual', new LinkReturnQuestionnairesToFormDual(this));
            dispatchQueue.set('RenderFormDual',                     new RenderFormDual(this));
            // dispatchQueue.set('CompleteGroupedParameterValues',     new SetUrlManagerParameter(this));
        },

        setupCreateReturnQueue()
        {
            createReturnQueue.set('ValidateReturns',                new ValidateReturns(this));
            createReturnQueue.set('PriceReturns',                   new PriceReturns(this));
            createReturnQueue.set('CreateReturns',                  new CreateReturns(this));
            createReturnQueue.set('NavigateToReturnConfirmation',   new NavigateToReturnConfirmation(this));
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

</style>
