
import {
    sortBy,
    uniqBy,
} from 'lodash-es';
import Parameter from "@/models/Parameter";
import FormSection from "./form-sections/FormSection";

export default class FormDual
{
    parameters = {
        left:  null,
        right: null,
    };

    formSections = [];

    /**
     * Global form error.
     */
    errors = {
        left:  null,
        right: null,
    };

    constructor()
    {
        this.parameters.left = new Map();
        this.parameters.right = new Map();
    }

    //-------------------------------------------------------------------------- RENDER

    render()
    {
        let parameterOrders = [];

        this.parameters.left.forEach(parameter =>
        {
            parameterOrders.push({
                code:         parameter.code,
                sort_order:   parameter.sort_order,
                form_display: parameter.form_display,
            });
        });

        this.parameters.right.forEach(parameter =>
        {
            parameterOrders.push({
                code:         parameter.code,
                sort_order:   parameter.sort_order,
                form_display: parameter.form_display,
            });
        });

        parameterOrders = parameterOrders.filter(parameter => parameter.form_display !== 'HIDDEN');
        parameterOrders = uniqBy(parameterOrders, 'code');
        let sortedParameterOrders = sortBy(parameterOrders, 'sort_order');

        console.log('Parameters order:', sortedParameterOrders);

        let formSections = [];

        for(let sortedParameterOrder of sortedParameterOrders)
        {
            let currentFormSection = formSections[formSections.length - 1];
            let currentParameterCode = sortedParameterOrder.code;

            let leftParameter = this.parameters.left.get(currentParameterCode);

            if(leftParameter)
            {
                if(!currentFormSection || currentFormSection.getCode() != leftParameter.getSectionCode())
                {
                    currentFormSection = new FormSection(leftParameter.getSectionCode());
                    formSections.push(currentFormSection);
                }

                currentFormSection.addParameter('left', leftParameter);
            }

            let rightParameter = this.parameters.right.get(currentParameterCode);

            if(rightParameter)
            {
                if(!currentFormSection || currentFormSection.getCode() != rightParameter.getSectionCode())
                {
                    currentFormSection = new FormSection(rightParameter.getSectionCode());
                    formSections.push(currentFormSection);
                }

                currentFormSection.addParameter('right', rightParameter);
            }
        }

        console.log('Form sections:', formSections);

        for(let cFormSection of formSections)
        {
            cFormSection.render();
        }

        this.formSections = formSections;
    }

    //-------------------------------------------------------------------------- SETTERS

    setParameters(side, parameters)
    {
        this.parameters[side] = new Map();

        parameters.forEach(parameter =>
        {
            this.parameters[side].set(parameter.code, parameter);
        });
    }

    //-------------------------------------------------------------------------- ERRORS

    setErrors(errors, side)
    {
        for(let paramCode in errors)
        {
            let messages = errors[paramCode];

            if(paramCode == 'global')
            {
                if(Array.isArray(messages))
                {
                    this.errors[side] = messages[0];
                }
                else
                {
                    this.errors[side] = messages;
                }
            }
            else
            {
                if(this.parameters[side].has(paramCode))
                {
                    let message = Array.isArray(messages) ? messages[0] : messages;

                    this.parameters[side].get(paramCode).setError(message);
                }
            }
        }
    }

    hasErrors(side = null)
    {
        if(side)
        {
            return this.errors[side] != null;
        }

        return this.errors.left != null || this.errors.right != null;
    }

    getError(side)
    {
        return this.errors[side];
    }

    resetError(side)
    {
        this.errors[side] = null;
    }
}
