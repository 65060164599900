export default class CheckNotAlreadyReturned
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const returns = [
            this.v.leftReturn,
            this.v.rightReturn,
        ];

        for(let ret of returns)
        {
            if(ret && ret.order.has_been_returned)
            {
                let tkey = (ret.isMain) ? 'return.form.error.already_returned' : 'return.form.error.paired_already_returned';

                this.v.orderIdentifierFormError = this.v.$t(tkey);

                return false;
            }
        }

        return true;
    }
}
