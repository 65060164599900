<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->
<!-- eslint-disable vue/no-v-html -->

<template>
    <div
        class="sl-order-form__line-column"
        :class="columnClasses"
        @click.stop="$refs.input.focus()"
    >
        <!-- Label -->
        <div class="sl-order-form__label-wrapper">
            <span class="sl-order-form__label">
                {{ parameter.form_label }}
            </span>

            <span v-if="parameter.unit" class="sl-order-form__unit">
                [&nbsp;{{ parameter.unit }}&nbsp;]
            </span>

            <div v-if="helper || note" class="flex-grow"></div>

            <tippy v-if="helper || note">
                <mdi-icon
                    icon="mdiInformationOutline"
                    class="cursor-help inline-block align-middle ml-1 w-4 h-4 text-gray-400"
                />

                <template #content>
                    <div class="text-center text-gray-600">
                        <div class="text-gray-700 font-bold">
                            {{ parameter.form_label }}

                            <span v-if="parameter.unit" class="sl-order-form__unit">
                                [&nbsp;{{ parameter.unit }}&nbsp;]
                            </span>
                        </div>

                        <div
                            v-if="helper"
                            class="sl-order-form__helper"
                            v-html="helper"
                        >
                        </div>

                        <div
                            v-if="note"
                            class="sl-order-form__note"
                            v-html="note"
                        >
                        </div>
                    </div>
                </template>
            </tippy>
        </div>

        <!-- Field -->
        <div class="sl-order-form__field-wrapper">
            <input
                ref="input"
                v-model.lazy="tempValue"
                class="sl-order-form__input"
                :class="{ 'cursor-not-allowed': disabled }"
                type="text"
                :name="name"
                :tabindex="tabIndex"
                autocomplete="off"
                :disabled="disabled"
                @focus="onFocus"
                @blur="onBlur"
            >
        </div>

        <!-- Error -->
        <div v-if="error" class="mb-1 px-2 text-red-600 text-xs text-center">
            {{ error }}
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { toNumber } from 'lodash-es';
import AbstractInlineField from './AbstractInlineField.vue';

export default
{
    name: 'InlineInputNumberField',

    extends: AbstractInlineField,

    emits: ['update:modelValue'],

    data()
    {
        return {
            isTouched: false,
            isFocused: false,
        };
    },

    computed:
    {
        helper()
        {
            const { min, max, step } = this.parameter;
            if(min === null || max === null || step === null)
            {
                return null;
            }

            const fmtMin  = this.format(min);
            const fmtMax  = this.format(max);
            const fmtStep = (this.format(step) + '').replace(/\+|-/g, '');

            const range = `${fmtMin}&nbsp;&rarr;&nbsp;${fmtMax}
                <br>
                &plusmn;&nbsp;${fmtStep}`;

            return `<div>${range}</div>` +
            `<div></div>`;
        },

        note()
        {
            return this.parameter.note;
        },

        disabled()
        {
            return !this.parameter.is_enabled;
        },

        tempValue:
        {
            get()
            {
                if(this.isFocused)
                {
                    return this.modelValue;
                }
                else
                {
                    return this.formattedValue;
                }
            },

            set(value)
            {
                if(value === null || value === this.value || value === '-' || value === '+')
                {
                    return;
                }

                if(typeof value === 'string')
                {
                    value = value.replace(',', '.');
                    value = value.replace(/[^0-9.-]/gi, '');
                }

                value = (value !== '') ? toNumber(value) : null;

                this.$emit('update:modelValue', value);
            },
        },
    },

    methods:
    {
        onFocus()
        {
            this.isFocused = true;
            this.$nextTick(() =>
            {
                this.$refs.input.select();
            });
        },

        onBlur()
        {
            this.isFocused = false;
            this.isTouched = true;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__line-column,
.sl-order-form__unit
{
    @apply cursor-text;
}

.sl-order-form__line-column .sl-order-form__field-wrapper
{
    @apply mx-0;
}

.sl-order-form__input
{
    @apply border-none text-left bg-transparent;

    // Highlight focused input
    &:focus
    {
        @apply outline-none;
    }
}

.sl-order-form__note
{
    @apply italic;
}

.sl-order-form__helper + .sl-order-form__note
{
    @apply mt-2;
}
</style>
