<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->
<!-- eslint-disable vue/no-v-html -->

<template>
    <div class="sl-order-form__line sl-order-form__line-splitted sl-order-form__line--unpadded">
        <!-- Right Parameter -->
        <field-factory
            v-if="line.rightParameter"
            side="right"
            :index="index"
            :parameter="line.rightParameter"
            @templated="$emit('templated', $event)"
        />

        <no-field v-else side="right" />

        <!-- Label -->
        <div class="sl-order-form__label-wrapper text-center">
            <label
                v-if="parameter.form_label !== 'NO_LABEL'"
                class="sl-order-form__label text-center leading-5"
                v-html="parameter.form_label"
            ></label>

            <span v-if="parameter.unit" class="sl-order-form__unit">
                [&nbsp;{{ parameter.unit }}&nbsp;]
            </span>

            <!-- Help -->
            <tippy v-if="help" class="sl-order-form__help">
                <template #content>
                    <div class="sl-order-form__help-text" v-html="help"></div>
                </template>

                <mdi-icon icon="mdiInformationOutline" />
            </tippy>
        </div>

        <!-- Left Parameter -->
        <field-factory
            v-if="line.leftParameter"
            side="left"
            :index="index"
            :parameter="line.leftParameter"
            @templated="$emit('templated', $event)"
        />

        <no-field v-else side="left" />
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import FieldFactory from './FieldFactory.vue';
import NoField      from '@/views/order/lenses/fields/NoField.vue';

export default
{
    name: 'SplittedFormLine',

    components:
    {
        FieldFactory,
        NoField,
    },

    props:
    {
        index:
        {
            type:     Number,
            required: true,
        },

        code:
        {
            type:     String,
            required: true,
        },

        line:
        {
            type:     Object,
            required: true,
        },
    },

    emits: ['templated'],

    computed:
    {
        parameter()
        {
            return this.line.rightParameter || this.line.leftParameter;
        },

        help()
        {
            if(this.parameter.help === null)
            {
                return null;
            }

            return '<p>' +
                this.parameter.help.split('\n').join('</p><p>') +
                '</p>';
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>
