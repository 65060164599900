import axios from '@/axios';
import Order from '@/models/Order';
import Return from '@/models/Return';
import LensReturnUrlManager from '../LensReturnUrlManager';

export default class FetchOrderByLensSn
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        /** @type {LensReturnUrlManager} */
        const lensReturnUrlManager = this.v.lensReturnUrlManager;

        // Skip unless there's a lens serial number in the URL
        if(!lensReturnUrlManager.hasLensSn())
        {
            console.log(`No lens SN in URL`);
            return true;
        }

        const urlLensSn = lensReturnUrlManager.getLensSn();
        console.log(`There is a lens SN in URL: ${urlLensSn}`);

        return axios.get(`/api/order/lens-sn/${urlLensSn}`)
            .then(response =>
            {
                // Set return
                const order = new Order(response.data);
                const ret = new Return(order);
                ret.isMain = true;
                this.v.setReturn(ret);

                console.log(`Main return has been set.`, ret);

                return true;
            })
            .catch(err =>
            {
                this.v.orderIdentifierFormError = this.v.$t('return.form.error.not_found');

                return false;
            });
    }
}
