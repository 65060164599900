import axios from '@/axios';
import Order from '@/models/Order';
import Return from '@/models/Return';
import LensReturnUrlManager from '../LensReturnUrlManager';

export default class FetchPairedOrderByReference
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        /** @type {LensReturnUrlManager} */
        const lensReturnUrlManager = this.v.lensReturnUrlManager;

        // Check if both lenses must be returned
        if(lensReturnUrlManager.getReturnBothLenses())
        {
            console.log(`Paired lens order must be retrieved`);

            // Get first retrieved return (left or right)
            const ret = this.v.leftReturn ?? this.v.rightReturn;

            return axios.get(`/api/order/paired/reference/${ret.order.reference}`)
                .then(response =>
                {
                    // Set return
                    const order = new Order(response.data);
                    const ret = new Return(order);
                    this.v.setReturn(ret);

                    console.log(`Paired return has been set.`);

                    return true;
                })
                .catch(error =>
                {
                    this.v.orderIdentifierFormError = this.v.$t('return.form.error.paired_not_found');

                    return false;
                });
        }
        else
        {
            console.log(`Paired lens order must NOT be retrieved`);
        }

        return true;
    }
}
