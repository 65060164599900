<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <lsn-modal :is-open="isOpen">

        <div v-if="!loading" class="sl-prompt sl-prompt--info sl-choose-entity-modal">
            <!-- Content -->
            <div class="sl-prompt__contents">

                <div class="sl-prompt__title">
                    {{ $t('return.choose_entity_modal.title') }}
                </div>

                <div class="sl-prompt__text">
                    <div class="mb-4 leading-6 text-gray-500">
                        {{ $t('return.choose_entity_modal.text') }}
                    </div>

                    <div class="grid gap-4 grid-cols-2">

                        <!-- Current entity -->
                        <div class="grow p-4 border border-primary-200 rounded-xl cursor-pointer transition hover:bg-primary-10 hover:border-primary-300" @click="close">
                            <div class="flex flex-col grow h-full">
                                <div class="mb-3 text-sm text-primary-500">
                                    &nbsp;
                                </div>

                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <div class="mb-3 text-sm text-gray-500" v-html="$f(getDeliveryAddress(cEntity), 'address')" />

                                <div class="grow">
                                    <!-- Align customer number vertically -->
                                </div>

                                <div class="text-sm text-gray-500">
                                    {{ $t('return.choose_entity_modal.customer_number') }}
                                    {{ cEntity.ext_id }}
                                </div>
                            </div>
                        </div>

                        <!-- Return entity -->
                        <div class="grow p-4 border border-primary-200 rounded-xl cursor-pointer transition hover:bg-primary-10 hover:border-primary-300" @click="switchEntity">
                            <div class="flex flex-col grow h-full">
                                <div class="mb-3 text-sm text-primary-500">
                                    {{ $t('common.sn_colon') }}
                                    {{ ret.order.serial_number }}
                                </div>

                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <div class="mb-3 text-sm text-gray-500" v-html="$f(getDeliveryAddress(returnEntity), 'address')" />

                                <div class="grow">
                                    <!-- Align customer number vertically -->
                                </div>

                                <div class="text-sm text-gray-500">
                                    {{ $t('return.choose_entity_modal.customer_number') }}
                                    {{ returnEntity.ext_id }}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <!-- Modal Buttons -->
            <div class="sl-prompt__actions">
                <button class="sl-prompt__button sl-prompt__button--white" @click="close">
                    {{ $t('common.cancel') }}
                </button>
            </div>

        </div>

    </lsn-modal>

</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import axios from '@/axios';

export default
{
    name: 'ChooseEntityModal',

    props:
    {
        isOpen:
        {
            type:    Boolean,
            default: false,
        },
        ret:
        {
            type:    Object,
            default: function()
            {
                return {};
            },
        },
    },

    emits: ['close', 'next'],

    data()
    {
        return {
            loading:      true,
            returnEntity: null,
        };
    },

    computed:
    {
        cEntity()
        {
            return this.$store.state.account.cEntity;
        },
    },

    watch:
    {
        isOpen(isOpen)
        {
            if(isOpen)
            {
                this.loading = true;

                axios.get('/api/entity/' + this.ret.order.order_by).then(response =>
                {
                    this.returnEntity = response.data;
                }).finally(() =>
                {
                    this.loading = false;
                });
            }
            else
            {
                this.returnEntity = null;
            }
        },
    },

    methods:
    {
        fetchEntity(eid)
        {
            const url = ('/api/entity/:eid').replace(':eid', eid);

            return axios.get(url);
        },

        getDeliveryAddress(entity)
        {
            return entity.addresses.find(a => a.used_for === 'SHIPPING');
        },

        switchEntity()
        {
            const path = this.$route.path.replace(this.cEntity.id, this.returnEntity.id);

            this.$router.push({ name: 'initializing' });

            this.$store.dispatch('initialize', this.returnEntity.id)
                .then(() =>
                {
                    this.$router.replace({
                        path: path,
                    });
                });

            this.close();
        },

        close()
        {
            this.$emit('close');
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.sl-choose-entity-modal
{
    width: 550px;
}

.sl-choose-entity-modal--wider
{
    width: 650px;
}

</style>
