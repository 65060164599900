<!-- eslint-disable vue/no-v-html -->
<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="card">
        <div class="card-header">
            <div class="card-header__icon-wrapper">
                <mdi-icon icon="mdiNewspaperVariantMultipleOutline" class="card-header__icon" />
            </div>

            <div class="card-header__title">
                {{ $t('home.widgets.news.title') }}
            </div>
        </div>

        <div v-if="hasNews" class="w-full text-center">
            <!-- <opti-munich-promotion
                v-if="hasOptiTicket"
                format="stacked"
                class="m-4"
            /> -->

            <div v-if="cEntity.country == 'FR'" class="">
                <img src="/img/opto-meetings-2025.jpg" />
            </div>

            <div
                v-for="(n, index) in news"
                :key="index"
                class="border-b border-gray-200 py-3 px-4 text-left text-gray-600 text-sm"
                v-html="n"
            >
            </div>
        </div>

        <div v-else class="no-news">
            {{ $t('home.widgets.news.none') }}
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import constants from '@/constants/constants';
import OptiMunichPromotion from '@/components/promotions/OptiMunichPromotion.vue';
import useOptiMunichPromotionComposable from '@/composables/OptiMunichPromotionComposable';

export default
{
    name: 'NewsWidget',

    components:
    {
        OptiMunichPromotion,
    },

    setup()
    {
        const { hasOptiTicket } = useOptiMunichPromotionComposable();

        return {
            // Computed
            hasOptiTicket,
        };
    },

    data()
    {
        return {
            news: [],
        };
    },

    computed:
    {
        cEntity()
        {
            return this.$store.state.account.cEntity;
        },

        hasNews()
        {
            return this.hasOptiTicket || this.news.length;
        },
    },

    created()
    {
        if(['AT', 'DE'].includes(this.cEntity.country))
        {
            this.news.push(this.$t('home.widgets.news.new_german_phone_number'));
        }
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.card
{
    @apply mr-8 w-1/2 min-h-[300px];
}

.news-item
{
}

.no-news
{
    @apply mt-8 w-full text-center text-gray-500;
}
</style>
