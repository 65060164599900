// =============
// Returns Module
// =============

import axios from '@/axios';

export default
{
    namespaced: true,


    // ------------------------------------------------------------ STATE

    state()
    {
        return {
            set:   [],
            total: 0,
        };
    },


    // ------------------------------------------------------------ GETTERS

    getters:
    {

    },


    // ------------------------------------------------------------ MUTATIONS

    mutations:
    {
        setReturnSet(state, returns)
        {
            state.set = returns;
        },

        setTotal(state, total)
        {
            state.total = total;
        },
    },


    // ------------------------------------------------------------ ACTIONS

    actions:
    {
        fetchReturns({ commit, rootState }, { offset, limit })
        {
            const url = '/api/return/entity/:eid/:offset/:limit'
                .replace(':eid', rootState.account.cEntity.id)
                .replace(':offset', offset)
                .replace(':limit', limit);

            return axios.get(url)
                .then(response =>
                {
                    commit('setReturnSet', response.data);
                    commit('setTotal', response.meta.total);

                    return response;
                });
        },

        searchReturns({ commit, rootState }, { offset, limit, query = '', dateFrom, dateTo, prototypeCode, articleCode, patientReference1, extRef })
        {
            return new Promise((resolve, reject) =>
            {
                let url = '/api/return/search/entity/:eid/:offset/:limit?q=:searchText'
                    .replace(':eid', rootState.account.cEntity.id)
                    .replace(':offset', offset)
                    .replace(':limit', limit)
                    .replace(':searchText', query);

                // Append advanced filters
                if(dateFrom)          url += `&date_from=${dateFrom}`;
                if(dateTo)            url += `&date_to=${dateTo}`;
                if(prototypeCode)     url += `&prototype_code=${prototypeCode}`;
                if(articleCode)       url += `&article_code=${articleCode}`;
                if(patientReference1) url += `&patient_reference1=${patientReference1}`;
                if(extRef)            url += `&ext_ref=${extRef}`;

                axios.get(url)
                    .then(({ data: returns, meta }) =>
                    {
                        commit('setReturnSet', returns);
                        commit('setTotal', meta.total);

                        resolve(returns);
                    })
                    .catch(error =>
                    {
                        reject(error);
                    });
            });
        },
    },
};
