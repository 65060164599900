<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <main-layout>

        <!-- Loaded -->
        <div v-if="!pageLoading" class="sl-page">

            <!-- Page Header -->
            <div class="sl-page__header flex justify-between">
                <!-- Page Title -->
                <div class="sl-page__title">
                    {{ $t('returns.title') }}
                </div>
            </div>

            <!-- table tools -->
            <div class="sl-page__tools">
                <!-- Search Field -->
                <!-- <div class="sl-search">
                    <mdi-icon icon="mdiMagnify" />

                    <input
                        ref="searchField"
                        v-model="searchText"
                        type="text"
                        class="sl-search__field"
                        @input="searchField_OnInput"
                    >

                    <tippy class="absolute top-1.5 right-2.5">
                        <template #content>
                            {{ $t('orders.advanced_filters.toggle') }}
                        </template>

                        <mdi-icon
                            :icon="showAdvancedFilters ? 'mdiFilter' : 'mdiFilterOutline'"
                            class="cursor-pointer w-[20px] h-[20px] text-gray-400 transition-colors outline-none hover:text-primary-500 focus-visible:text-primary-500"
                            :class="{ 'text-primary-300': showAdvancedFilters }"
                            tabindex="0"
                            @click="toggleAdvancedFilters"
                            @keypress.enter="toggleAdvancedFilters"
                        />
                    </tippy>
                </div> -->

                <div class="grow"></div>

                <!-- Pagination -->
                <div v-if="!searching && returns.length" class="pagination">
                    <!-- Results per Page -->
                    <tippy>
                        <template #content>
                            {{ $t('pagination.results_per_page.label') }}
                        </template>

                        <div class="pagination__page-size relative">
                            <span>{{ pageSize }}</span>

                            <mdi-icon icon="mdiMenuDown" />

                            <select v-model="pageSize">
                                <option
                                    v-for="value in pageSizes"
                                    :key="`ps${value}`"
                                    :value="value"
                                >
                                    {{ value }}
                                </option>
                            </select>
                        </div>
                    </tippy>

                    <!-- Previous Button -->
                    <div v-if="cPage !== 1" class="pagination__page" @click="previous">
                        &lt;
                    </div>

                    <!-- Pages -->
                    <template v-for="index in pages">
                        <div
                            v-if="typeof index === 'number'" :key="`a${index}`"
                            class="pagination__page"
                            :class="{ 'pagination__page--active' : index === cPage }"
                            @click="setCurrentPage(index)"
                        >
                            {{ index }}
                        </div>

                        <div v-else :key="`b${index}`" class="pagination__spacer">
                            {{ index }}
                        </div>
                    </template>

                    <!-- Next Button -->
                    <div v-if="cPage !== totalPages" class="pagination__page" @click="next">
                        &gt;
                    </div>
                </div>

                <div class="w-full"></div>

                <!-- Advanced Filters -->
                <div v-if="showAdvancedFilters" class="advanced-filters">
                    <div class="advanced-filters-arrow"></div>

                    <div class="mb-2">
                        <div class="inline-block align-middle text-base text-primary-600">
                            {{ $t('orders.advanced_filters.title') }}
                        </div>

                        <!-- Clear Filters Button -->
                        <div
                            v-if="hasAdvancedFilters"
                            class="cursor-pointer inline-block align-middle ml-2 text-primary-500 text-xs font-normal hover:underline focus-visible:underline"
                            @click="clearAdvancedFilters"
                        >
                            {{ $t('common.clear_filters') }}
                        </div>
                    </div>

                    <div class="advanced-filters__left">
                        <!-- Left Side: Date Filters -->
                        <div class="flex flex-col gap-1 w-[120px]">
                            <!-- Filter: From Date -->
                            <div
                                v-click-outside="closeDatePickerFrom"
                                class="advanced-filter advanced-filter--button"
                                :class="{ 'advanced-filter--has-value': advancedFilters.dateFrom }"
                                @click.self="showDatePickerFrom = !showDatePickerFrom"
                            >
                                <mdi-icon icon="mdiCalendarArrowRight" class="advanced-filter__icon mr-2" />

                                <span class="advanced-filter__caption">
                                    <template v-if="advancedFilters.dateFrom">
                                        {{ formatDate(advancedFilters.dateFrom) }}
                                    </template>

                                    <template v-else>
                                        {{ $t('orders.advanced_filters.date_from.label') }}
                                    </template>
                                </span>

                                <div
                                    v-if="showDatePickerFrom"
                                    class="absolute left-1 top-full mt-1 z-10"
                                >
                                    <v-date-picker
                                        v-model="advancedFilters.dateFrom"
                                        :locale="$route.params.lang"
                                        class="shadow-md"
                                        color="primary"
                                        @dayclick="closeDatePickerFrom"
                                    />
                                </div>
                            </div>

                            <!-- Filter: To Date -->
                            <div
                                v-click-outside="closeDatePickerTo"
                                class="advanced-filter advanced-filter--button"
                                :class="{ 'advanced-filter--has-value': advancedFilters.dateTo }"
                                @click.self="showDatePickerTo = !showDatePickerTo"
                            >
                                <mdi-icon icon="mdiCalendarArrowLeft" class="advanced-filter__icon mr-2" />

                                <span class="advanced-filter__caption">
                                    <template v-if="advancedFilters.dateTo">
                                        {{ formatDate(advancedFilters.dateTo) }}
                                    </template>

                                    <template v-else>
                                        {{ $t('orders.advanced_filters.date_to.label') }}
                                    </template>
                                </span>

                                <div
                                    v-if="showDatePickerTo"
                                    class="absolute left-1 top-full mt-1 z-10"
                                >
                                    <v-date-picker
                                        v-model="advancedFilters.dateTo"
                                        :locale="$route.params.lang"
                                        class="shadow-md"
                                        color="primary"
                                        @dayclick="closeDatePickerTo"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- Right Side Filters -->
                        <div class="advanced-filters__right">
                            <!-- Filter: Prototype -->
                            <div
                                class="advanced-filter advanced-filter--select min-w-[30%] max-w-[33.33333%]"
                                :class="{ 'advanced-filter--has-value': !!advancedFilters.prototypeCode }"
                            >
                                <span class="advanced-filter__caption">
                                    <template v-if="advancedFilters.prototypeCode">
                                        {{ prototypes.find(p => p.code === advancedFilters.prototypeCode).label }}
                                    </template>

                                    <template v-else>
                                        {{ $t('orders.advanced_filters.prototype.label') }}
                                    </template>
                                </span>

                                <mdi-icon icon="mdiMenuDown" class="advanced-filter__icon ml-1" />

                                <select v-model="advancedFilters.prototypeCode">
                                    <option :value="null">
                                        {{ $t('orders.advanced_filters.prototype.any') }}
                                    </option>

                                    <option
                                        v-for="prototype in prototypes"
                                        :key="prototype.code"
                                        :value="prototype.code"
                                    >
                                        {{ prototype.label }}
                                    </option>
                                </select>
                            </div>

                            <!-- Filter: Article -->
                            <div
                                class="advanced-filter advanced-filter--select min-w-[30%] max-w-[33.33333%]"
                                :class="{ 'advanced-filter--has-value': !!advancedFilters.articleCode }"
                            >
                                <span class="advanced-filter__caption">
                                    <template v-if="advancedFilters.articleCode">
                                        {{ articles.find(a => a.code === advancedFilters.articleCode).label }}
                                    </template>

                                    <template v-else>
                                        {{ $t('orders.advanced_filters.article.label') }}
                                    </template>
                                </span>

                                <mdi-icon icon="mdiMenuDown" class="advanced-filter__icon ml-1" />

                                <select v-model="advancedFilters.articleCode">
                                    <option :value="null">
                                        {{ $t('orders.advanced_filters.article.any') }}
                                    </option>

                                    <option
                                        v-for="article in articles"
                                        :key="article.code"
                                        :value="article.code"
                                    >
                                        {{ article.label }}
                                    </option>
                                </select>
                            </div>

                            <!-- Filter: Status -->
                            <div
                                class="advanced-filter advanced-filter--select min-w-[30%] max-w-[33.33333%]"
                                :class="{ 'advanced-filter--has-value': !!advancedFilters.statusCodes }"
                            >
                                <span class="advanced-filter__caption">
                                    <template v-if="advancedFilters.statusCodes">
                                        {{ statuses.find(s => s.code === advancedFilters.statusCodes).label }}
                                    </template>

                                    <template v-else>
                                        {{ $t('orders.advanced_filters.status.label') }}
                                    </template>
                                </span>

                                <mdi-icon icon="mdiMenuDown" class="advanced-filter__icon ml-1" />

                                <select v-model="advancedFilters.statusCodes">
                                    <option :value="null">
                                        {{ $t('orders.advanced_filters.status.any') }}
                                    </option>

                                    <option
                                        v-for="status in statuses"
                                        :key="status.code"
                                        :value="status.code"
                                    >
                                        {{ status.label }}
                                    </option>
                                </select>
                            </div>

                            <!-- Filter: Patient Reference -->
                            <div
                                class="advanced-filter advanced-filter--select max-w-[50%]"
                                :class="{ 'advanced-filter--has-value': !!advancedFilters.patientReference1 }"
                            >
                                <span class="advanced-filter__caption">
                                    <template v-if="advancedFilters.patientReference1">
                                        {{ advancedFilters.patientReference1 }}
                                    </template>

                                    <template v-else>
                                        {{ $t('orders.advanced_filters.patient_reference.label') }}
                                    </template>
                                </span>

                                <mdi-icon icon="mdiMenuDown" class="advanced-filter__icon ml-1" />

                                <select v-model="advancedFilters.patientReference1">
                                    <option :value="null">
                                        {{ $t('orders.advanced_filters.patient_reference.any') }}
                                    </option>

                                    <option
                                        v-for="patient in patients"
                                        :key="patient"
                                        :value="patient"
                                    >
                                        {{ patient }}
                                    </option>
                                </select>
                            </div>

                            <!-- Filter: External Reference -->
                            <div
                                class="advanced-filter advanced-filter--select max-w-[50%]"
                                :class="{ 'advanced-filter--has-value': !!advancedFilters.extRef }"
                            >
                                <span class="advanced-filter__caption">
                                    <template v-if="advancedFilters.extRef">
                                        {{ advancedFilters.extRef }}
                                    </template>

                                    <template v-else>
                                        {{ $t('orders.advanced_filters.external_reference.label') }}
                                    </template>
                                </span>

                                <mdi-icon icon="mdiMenuDown" class="advanced-filter__icon ml-1" />

                                <select v-model="advancedFilters.extRef">
                                    <option :value="null">
                                        {{ $t('orders.advanced_filters.external_reference.any') }}
                                    </option>

                                    <option
                                        v-for="extRef in extRefs"
                                        :key="extRef"
                                        :value="extRef"
                                    >
                                        {{ extRef }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Page Content -->
            <div v-if="!resultsLoading" class="sl-page__content">
                <template v-if="returns.length">
                    <!-- Return Table -->
                    <return-table
                        class="sl-return-table"
                        :returns="returns"
                    />
                </template>
                <template v-else>
                    <div class="text-gray-500 text-sm mt-4 text-left">
                        {{ $t('common.search.no_result') }}
                    </div>
                </template>
            </div>

            <div v-else class="mt-8 text-center">
                <img class="inline" src="/img/loader-5.gif">
            </div>

        </div>

        <!-- Page Loading -->
        <template v-else>
            <div class="mt-8 mx-auto">
                <img class="inline" src="/img/loader-5.gif">
            </div>
        </template>

    </main-layout>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import { DatePicker as VDatePicker } from 'v-calendar';
import { debounce } from 'lodash-es';
import moment       from 'moment';
import MainLayout   from '@/layouts/MainLayout.vue';
import ReturnTable from '@/components/return/ReturnTable.vue';
import constants    from '@/constants/constants';
import axios        from '@/axios';

export default
{
    components:
    {
        MainLayout,
        ReturnTable,
        VDatePicker,
    },

    data()
    {
        return {
            pageLoading:    true,
            resultsLoading: false,
            searching:      false,
            searchText:     '',
            pageSize:       10,
            cPage:          1,

            // Advanced filters
            showAdvancedFilters: false,
            advancedFilters:
            {
                dateFrom:          null,
                dateTo:            null,
                prototypeCode:     null,
                articleCode:       null,
                patientReference1: null,
                extRef:            null,
            },

            showDatePickerFrom: false,
            showDatePickerTo:   false,

            extRefs: [],

            fetchingPatients: false,
            fetchingExtRefs:  false,
        };
    },

    computed:
    {
        pageSizes()
        {
            return [10, 25, 50];
        },

        returns()
        {
            return this.$store.state.returns.set;
        },

        total()
        {
            return this.$store.state.returns.total;
        },

        totalPages()
        {
            return Math.ceil(this.total / this.pageSize) || 0;
        },

        pages()
        {
            if(!this.total)
            {
                return [];
            }

            const pages = [];

            if(this.totalPages <= 5)
            {
                for(let i = 1; i <= this.totalPages; i += 1)
                {
                    pages.push(i);
                }
            }
            else
            {
                let start = this.cPage - 1;
                let end = this.cPage + 1;

                if(start < 2)
                {
                    const delta = Math.abs(2 - start);
                    start += delta;
                    end   += delta;
                }

                if(end > (this.totalPages - 1))
                {
                    const delta = Math.abs(end - (this.totalPages - 1));
                    start -= delta;
                    end   -= delta;
                }

                pages.push(1);

                if(start > 2)
                {
                    pages.push('…');
                }

                for(let i = start; i <= end; i += 1)
                {
                    pages.push(i);
                }

                if(end < (this.totalPages - 1))
                {
                    pages.push('…');
                }

                if(end < this.totalPages)
                {
                    pages.push(this.totalPages);
                }
            }

            return pages;
        },

        patients()
        {
            return this.$store.state.patients.all;
        },

        hasAdvancedFilters()
        {
            return Object.values(this.advancedFilters).some(f => !!f);
        },
    },

    watch:
    {
        pageSize()
        {
            this.searchReturns();
        },

        'advancedFilters.dateFrom'()
        {
            this.checkDatesOrder();
            this.searchReturns();
        },

        'advancedFilters.dateTo'()
        {
            this.checkDatesOrder();
            this.searchReturns();
        },

        'advancedFilters.prototypeCode'()
        {
            this.searchReturns();
        },

        'advancedFilters.articleCode'()
        {
            this.searchReturns();
        },

        'advancedFilters.statusCodes'()
        {
            this.searchReturns();
        },

        'advancedFilters.patientReference1'()
        {
            this.searchReturns();
        },

        'advancedFilters.extRef'()
        {
            this.searchReturns();
        },
    },

    mounted()
    {
        this.searchReturns()
            .then(() =>
            {
                this.pageLoading = false;

                this.$nextTick(() => this.$refs.searchField?.focus());
            });
    },

    methods:
    {
        previous()
        {
            this.setCurrentPage(this.cPage - 1);
        },

        next()
        {
            this.setCurrentPage(this.cPage + 1);
        },

        setCurrentPage(pageNumber, forceSearch = false)
        {
            pageNumber = parseInt(pageNumber);
            if(this.cPage !== pageNumber)
            {
                this.cPage = pageNumber;

                // Load search results for the new page
                this.searchReturns();
            }
            else if(forceSearch)
            {
                this.searchReturns();
            }
        },

        searchField_OnInput: debounce(function()
        {
            // Always reload results from page #1
            this.searching = true;
            this.setCurrentPage(1, true);
        }, 800),

        searchReturns()
        {
            this.resultsLoading = true;

            // If the current page number is greater than the number of pages
            // for the new page size, reset the cursor to page #1.
            if((this.cPage - 1) * this.pageSize > this.total)
            {
                this.cPage = 1;
            }

            const options = {
                offset: (this.cPage - 1) * this.pageSize,
                limit:  this.pageSize,
                query:  this.searchText,

                // Advanced filters
                dateFrom:          this.formatDate(this.advancedFilters.dateFrom, 'api'),
                dateTo:            this.formatDate(this.advancedFilters.dateTo, 'api'),
                prototypeCode:     this.advancedFilters.prototypeCode,
                articleCode:       this.advancedFilters.articleCode,
                patientReference1: this.advancedFilters.patientReference1,
                extRef:            this.advancedFilters.extRef,
            };

            return this.$store.dispatch('returns/searchReturns', options)
                .then(returns =>
                {
                    this.searching = false;
                    this.resultsLoading = false;

                    return returns;
                });
        },

        toggleAdvancedFilters()
        {
            if(this.showAdvancedFilters)
            {
                // Collapse filters
                this.showAdvancedFilters = false;
            }
            else
            {
                // Expand filters
                this.showAdvancedFilters = true;

                // Fetch patients & ext refs (if not already done)
                if(!this.patients.length)
                {
                    this.fetchPatients();
                }

                if(!this.extRefs.length)
                {
                    this.fetchExtRefs();
                }
            }
        },

        clearAdvancedFilters()
        {
            this.advancedFilters = {
                dateFrom:          null,
                dateTo:            null,
                prototypeCode:     null,
                articleCode:       null,
                patientReference1: null,
                extRef:            null,
            };
        },

        fetchPatients()
        {
            if(this.fetchingPatients)
            {
                return;
            }

            this.fetchingPatients = true;
            const eid = this.$store.state.account.cEntity.id;
            this.$store.dispatch('patients/fetchAll', eid)
                .then(patients =>
                {
                    // nothing
                })
                .catch(error =>
                {
                    // todo: handle error
                    console.log(error);
                })
                .then(() =>
                {
                    this.fetchingPatients = false;
                });
        },

        fetchExtRefs()
        {
            if(this.fetchingExtRefs)
            {
                return;
            }

            this.fetchingExtRefs = true;
            const eid = this.$store.state.account.cEntity.id;
            new Promise((resolve, reject) =>
            {
                axios.get(`/api/ext-refs/entity/${eid}`)
                    .then(({ data: extRefs }) => resolve(extRefs))
                    .catch(error => reject(error));
            })
                .then(extRefs =>
                {
                    this.extRefs = extRefs;
                })
                .catch(error =>
                {
                    this.extRefs = [];

                    // todo: handle error
                })
                .then(() =>
                {
                    this.fetchingExtRefs = false;
                });
        },

        formatDate(date, context = 'display')
        {
            if(!date)
            {
                return null;
            }

            switch(context)
            {
                case 'api':
                    return parseInt(moment(date).format('YYYYMMDD'));

                case 'display':
                default:
                    return moment(date).format('DD.MM.YYYY');
            }
        },

        openDatePickerFrom()
        {
            this.showDatePickerFrom = true;
        },

        closeDatePickerFrom()
        {
            this.showDatePickerFrom = false;
        },

        openDatePickerTo()
        {
            this.showDatePickerTo = true;
        },

        closeDatePickerTo()
        {
            this.showDatePickerTo = false;
        },

        /**
         * Ensure `dateFrom` is never greater than `dateTo`
         * by swapping them if necessary.
         *
         * @returns {void}
         */
        checkDatesOrder()
        {
            if(!this.advancedFilters.dateFrom || !this.advancedFilters.dateTo)
            {
                return;
            }

            if(this.advancedFilters.dateFrom > this.advancedFilters.dateTo)
            {
                [this.advancedFilters.dateFrom, this.advancedFilters.dateTo] = [this.advancedFilters.dateTo, this.advancedFilters.dateFrom];
            }
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.sl-page__tools
{
    @apply flex flex-wrap justify-between items-center mb-4;
}

.sl-search
{
    @apply relative;
}

.sl-search > .mdi-icon
{
    @apply absolute top-1.5 left-2.5 w-[22px] h-[22px] text-gray-400 pointer-events-none;
}

.sl-search__field
{
    @apply border border-gray-300 px-[34px] w-full h-8 text-primary-500 rounded-full appearance-none;
}

.sl-search__field:focus
{
    @apply outline-none;
}

.pagination
{
    @apply flex ml-auto select-none;
}

.pagination__page-size
{
    @apply cursor-pointer relative flex justify-between items-center border border-gray-300 pl-2 min-w-8 h-8 shrink-0 text-gray-500 text-sm;

    > span:first-child
    {
    }

    > .mdi-icon
    {
        @apply ml-1 w-5 h-5 shrink-0 text-gray-500;
    }

    > select
    {
        @apply cursor-pointer absolute top-0 right-0 bottom-0 left-0 opacity-0;
    }
}

.pagination__page
{
    @apply cursor-pointer flex justify-center items-center ml-2 p-2 border border-primary-100 min-w-8 h-8 shrink-0 text-primary-500 text-sm
        hover:bg-primary-10;
}

.pagination__page--active
{
    @apply bg-primary-100;
}

.pagination__spacer
{
    height: 32px;
    min-width: 32px;
    @apply ml-2 flex items-center justify-center text-sm text-gray-500 p-2;
}

// Advanced Filters
.advanced-filters
{
    @apply relative mt-2.5 rounded-md p-4 w-full text-sm text-gray-500 bg-primary-100;
}

.advanced-filters-arrow
{
    position: absolute;
    top: -20px;
    left: 208px;
    display: block;
    width: 20px;
    height: 20px;
    border: 10px solid transparent;
    border-bottom-color: theme('colors.primary.100');
}

.advanced-filters__left
{
    @apply flex justify-between gap-4;
}

.advanced-filters__right
{
    @apply flex flex-wrap grow justify-end gap-1;
}

.advanced-filter
{
    @apply cursor-pointer relative flex grow items-center rounded border border-gray-300 px-2 py-1 bg-white select-none
        hover:bg-gray-50
        focus-within:bg-gray-50;
}

.advanced-filter--button
{
    .mdi-icon,
    .advanced-filter__caption
    {
        @apply pointer-events-none;
    }
}

.advanced-filter--select
{
    @apply justify-between;

    select
    {
        @apply cursor-pointer absolute top-0 right-0 bottom-0 left-0 opacity-0;
    }
}

.advanced-filter__icon
{
    @apply inline-block align-middle w-5 h-5 shrink-0;
}

.advanced-filter__caption
{
    @apply inline-flex align-middle truncate;
}

.advanced-filter--has-value .advanced-filter__caption
{
    @apply text-primary-500;
}
</style>
