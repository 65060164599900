export default class PriceReturns
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const requests = [];
        const rightReturn = this.v.$store.getters['lensReturn/getReturn']('right');
        const leftReturn  = this.v.$store.getters['lensReturn/getReturn']('left');

        // Set overlay text
        this.v.$store.commit('overlay/setText', this.v.$t('overlay.pricing'));

        if(rightReturn !== null)
        {
            console.log('Price right side', rightReturn);
            requests.push(rightReturn.fetchPrice().catch(error =>
            {
                this.v.formDual.setErrors(error.response.errors[0], 'right');
                throw error;
            }));
        }

        if(leftReturn !== null)
        {
            console.log('Price left side', leftReturn);
            requests.push(leftReturn.fetchPrice().catch(error =>
            {
                this.v.formDual.setErrors(error.response.errors[0], 'left');
                throw error;
            }));
        }

        return Promise.all(requests)
            .then(response =>
            {
                console.log('Pricing OK !');
                return Promise.resolve(response);
            })
            .catch(error =>
            {
                console.log('Pricing error:', error);
                return Promise.reject(error);
            });
    }
}
