<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="sl-form-dual__line-side" :class="`sl-form-dual__line-side-${side}`">

        <!-- Helper (grey vertical band) -->
        <div class="sl-form-dual__line-band-wrapper">
            <div class="sl-form-dual__line-band-content"></div>
        </div>

        <!-- Error Wrapper -->
        <div class="sl-form-dual__line-content-wrapper">

            <!-- Field Wrapper -->
            <div class="sl-form-dual__field-wrapper flex-col">

                <!-- Field -->
                <div class="sl-form-dual__info px-4">
                    {{ price }}
                    <span v-if="cReturn.price_description" class="text-gray-500 text-xs">
                        &nbsp;({{ cReturn.price_description }})
                    </span>
                </div>

                <!-- Error -->
                <div v-if="cReturn.price_information?.message" class="mt-1 text-red-500 text-xs text-center">
                    {{ cReturn.price_information.message }}
                </div>

            </div>

        </div>

    </div>

</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

//import FormFieldAbstract from '@/components/forms/form-dual/form-sections/form-lines/FormFieldAbstract';

export default
{
    name: 'FormFieldReturnCreditAmount',

    //extends: FormFieldAbstract,

    props:
    {
        side:
        {
            type:     String,
            required: true,
        },

        field:
        {
            type:     Object,
            required: true,
        },
    },

    computed:
    {
        cReturn()
        {
            return this.$store.getters['lensReturn/getReturn'](this.side);
        },

        price()
        {
            if(this.cReturn.price !== null)
            {
                return this.$f(this.cReturn.price, 'decimal:2') + ' ' + this.cReturn.currency_code;
            }

            return null;
        },
    },
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">

.sl-form-dual__select:focus
{
    @apply outline-none border-primary-400;
}

</style>
