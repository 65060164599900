export default class ValidatePreviousOrdersEntitiesConsistency
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        // If there is at least one previous order (meaning: re-order).
        if(this.v.sides.right.previousOrder || this.v.sides.left.previousOrder)
        {
            // If both previous orders were retrieved.
            if(this.v.sides.right.previousOrder && this.v.sides.left.previousOrder)
            {
                // If both previous orders entities are different from current one => show entities selector modal.
                if(this.v.cEntity.id != this.v.sides.right.previousOrder.order_by && this.v.cEntity.id != this.v.sides.left.previousOrder.order_by)
                {
                    console.log(`Both previous orders entities are different from current one => show entities selector modal`);
                    this.v.showChooseEntityModal = true;

                    return false;
                }
            }

            let forceCurrentEntity = this.v.$route.query.force_current_entity;

            console.log(`Bypass entity check: ${forceCurrentEntity}`);

            // If only one of the previous order entity was different from current one => show
            // entities selector modal OR going straight if "force_current_entity" was given as URL query param.
            if(!forceCurrentEntity && this.v.sides.right.previousOrder && this.v.cEntity.id != this.v.sides.right.previousOrder.order_by)
            {
                console.log(`Right side entity is different from current one => show entities selector modal`);
                this.v.showChooseEntityModal = true;

                return false;
            }

            if(!forceCurrentEntity && this.v.sides.left.previousOrder && this.v.cEntity.id != this.v.sides.left.previousOrder.order_by)
            {
                console.log(`Left side entity is different from current one => show entities selector modal`);
                this.v.showChooseEntityModal = true;

                return false;
            }
        }

        return true;
    }
}
