/**
 * @typedef {import('@/composables/GraphComposable.js').Surface}  Surface
 */

import { radiusDeltaSagEx, daSag } from '@/utils/Formulas';
import ColoringStrategy2D from './ColoringStrategy2D';
import useGraphComposable from '@/composables/GraphComposable.js';
import constants from '@/constants/constants';
import Meridian from '@/models/Meridian';

const DEFAULT_COLOR_SCHEME = 'FLUO_GREEN';

const colors = constants.colorScales.TEAR_FILM;

const { getMeridian, getApex, getApexAlt360 } = useGraphComposable();

/**
 * Color the squares using the thickness of the tear film.
 */
export default class TearFilmColoringStrategy2D extends ColoringStrategy2D
{
    // ------------------------------------------------------------ MEMBERS

    /** @type {Surface} The surface of the cornea. */
    corneaPoints = [];

    /** @type {Surface} The back surface of the lens. */
    backSurfacePoints = [];

    /** @type {number} The total diameter of the lens. */
    dtot = 0;

    /** @type {number} The minimum thickness of the tear film. */
    minTear = 0;

    apex = 0;

    kMeridianCache  = {};
    clMeridianCache = {};


    // ------------------------------------------------------------ CONSTRUCTOR

    /**
     * @param {number}  dtot
     * @param {Surface} corneaPoints
     * @param {Surface} backSurfacePoints
     * @param {number}  minTear
     */
    constructor(dtot, corneaPoints, backSurfacePoints, minTear)
    {
        super();
        this.dtot = dtot;
        this.corneaPoints = corneaPoints;
        this.backSurfacePoints = backSurfacePoints;
        this.minTear = minTear;

        // Calculate the apex
        this.apex = getApexAlt360(corneaPoints, backSurfacePoints);
    }


    // ------------------------------------------------------------ METHODS

    /**
     * Get the color for the specified point.
     *
     * @param {Meridian} meridian The meridian being evalutated.
     * @param {number}   dia      The diameter being evaluated.
     * @param {string}   scheme   The color scheme to use.
     * @returns {string} The evaluated color code.
     */
    getColor(meridian, dia, scheme = DEFAULT_COLOR_SCHEME)
    {
        if(dia > this.dtot)
        {
            return '#000';
        }

        const kSag = meridian.sag(dia);
        const clSag = this.getCacheMeridian('back', meridian.angle).sag(dia);
        const val = Math.round((kSag - clSag + this.apex + this.minTear) * 1000);

        return colors[scheme].findLast(({ value }) => val > value)?.color
            ?? '#fff';
    }

    /**
     * Get the meridian at an angle and cache it for future reference.
     * The angle is rounded to the nearest integer,
     * so we're trading precision for performance.
     *
     * @param {string} surfaceName
     * @param {number} angle
     * @returns {Meridian}
     */
    getCacheMeridian(surfaceName, angle)
    {
        const cache = surfaceName === 'cornea'
            ? this.kMeridianCache
            : this.clMeridianCache;

        const cacheKey = angle.toFixed(0);
        if(!cache[cacheKey])
        {
            const surface = surfaceName === 'cornea'
                ? this.corneaPoints
                : this.backSurfacePoints;
            cache[cacheKey] = getMeridian(surface, Math.round(angle));
        }

        return cache[cacheKey];
    }
}
