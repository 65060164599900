/* ============
 * Routes File
 * ============
 *
 * The routes and redirects are defined in this file.
 */

import AppLayout            from '@/layouts/AppLayout.vue';
import HomePage             from '@/views/home/HomePage.vue';
import Login                from '@/views/Login.vue';
import EntitiesSwitchLayout from '@/layouts/EntitiesSwitchLayout.vue';
import Initializing         from '@/views/Initializing.vue';
import OrderLenses          from '@/views/order/lenses/LensOrder.vue';
import ReturnLenses         from '@/views/return/lenses/LensReturn.vue';
import PriceList            from '@/views/PriceList.vue';
import BasketPage           from '@/views/basket/BasketPage.vue';
import OrderConfirmation    from '@/views/order/OrderConfirmation.vue';
import ReturnConfirmation   from '@/views/return/ReturnConfirmation.vue';
import OrderList            from '@/views/order/List.vue';
import ReturnList            from '@/views/return/List.vue';
import InvoiceList          from '@/views/invoice/List.vue';
import ContactDetails       from '@/views/company/ContactDetails.vue';
import CleaningProductsList from '@/views/cleaning-products/List.vue';
import AccessoriesList      from '@/views/accessories/List.vue';
import TopographersList     from '@/views/topographers/List.vue';

export default[
    // -----------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------- UNAUTHENTICATED
    // -----------------------------------------------------------------------------------------

    // ------------------------------------------------------------------------- LOGIN

    {
        path:      '/:lang([a-z]{2})?/login',
        name:      'login',
        component: Login,
        meta:      { auth: false }, // If the user needs to be a guest to view this page.
    },

    // -----------------------------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------- AUTHENTICATED AND ENTITY SELECTED
    // -----------------------------------------------------------------------------------------------------------

    // ------------------------------------------------------------------------- INITIALISATION

    {
        path:      '/:lang([a-z]{2})?/initializing',
        name:      'initializing',
        component: Initializing,
        meta:      { auth: true },
    },

    // ------------------------------------------------------------------------- BASE

    {
        path:      '/:lang([a-z]{2})/:entity_id([A-Za-z0-9]{2,6})',
        name:      'base',
        component: AppLayout,
        meta:      { auth: true }, // If the user needs to be authenticated to view this page.

        children:
        [
            // ----------------------------------------------------------------- HOME

            {
                path:      'home',
                name:      'home',
                component: HomePage,
            },

            // ----------------------------------------------------------------- ORDER (placing)

            {
                path:      'order/lenses/edit/:sides*',
                name:      'edit-order-lenses',
                component: OrderLenses,
                meta:
                {
                    mainMenu: 'new_order',
                },
            },

            {
                path:      'order/lenses/:sides*',
                name:      'order-lenses',
                component: OrderLenses,
                meta:
                {
                    mainMenu: 'new_order',
                },
            },

            {
                path: 'order/solutions',
                name: 'order-solutions',
            },

            {
                path: 'order/accessories',
                name: 'order-accessories',
            },

            // ----------------------------------------------------------------- ORDERS LIST

            {
                path:      'order/list',
                name:      'order-list',
                component: OrderList,
            },

            // ----------------------------------------------------------------- RETURN

            {
                path:      'return/lenses/:lenses*',
                name:      'return-lenses',
                component: ReturnLenses,
            },

            // ----------------------------------------------------------------- RETURNS LIST

            {
                path:      'return/list',
                name:      'return-list',
                component: ReturnList,
            },

            // ----------------------------------------------------------------- BASKET

            {
                path:      'basket',
                name:      'basket',
                component: BasketPage,
            },

            // ----------------------------------------------------------------- INVOICING

            {
                path:      'invoice-list',
                name:      'invoice-list',
                component: InvoiceList,
            },

            // ----------------------------------------------------------------- CONTACT DETAILS

            {
                path:      'contact-details',
                name:      'contact-details',
                component: ContactDetails,
            },

            // ----------------------------------------------------------------- PRICE-LIST

            {
                path:      'price-list',
                name:      'price-list',
                component: PriceList,
            },

            // ----------------------------------------------------------------- ORDERS CONFIRMATION

            {
                path:      'order-confirmation',
                name:      'order-confirmation',
                component: OrderConfirmation,
            },

            // ----------------------------------------------------------------- RETURNS CONFIRMATION

            {
                path:      'return-confirmation',
                name:      'return-confirmation',
                component: ReturnConfirmation,
            },

            // ----------------------------------------------------------------- CLEANING PRODUCTS LIST

            {
                path:      'cleaning-products',
                name:      'cleaning-products',
                component: CleaningProductsList,
            },

            // ----------------------------------------------------------------- ACCESSORIES LIST

            {
                path:      'accessories',
                name:      'accessories',
                component: AccessoriesList,
            },

            // ----------------------------------------------------------------- TOPOGRAPHERS LIST

            {
                path:      'topographers',
                name:      'topographers',
                component: TopographersList,
            },

            // ------------------------------------------------------------ MY ACCOUNT

            {
                path:      'my-account',
                name:      'my-account',
                component: () => import('@/views/user/MyAccount.vue'),
            },
        ],
    },

    {
        path:      '/:lang([a-z]{2})?/entities-switch',
        name:      'entities-switch',
        component: EntitiesSwitchLayout,
        meta:      { auth: true },
    },

    // --------------------------------------------------------------------------------
    // ----------------------------------------------------------------- DEFAULT ROUTES
    // --------------------------------------------------------------------------------

    {
        path:     '/',
        redirect: { name: 'login' },
    },

    {
        path:     '/*',
        redirect: { name: 'login' },
    },
];
